import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import TicketListCompact from "../components/TicketList";
import { TicketManagementContext, withTicketManagementProvider } from "../TicketManagementProvider";
import { useLocation, useHistory } from "react-router-dom";

function PreventiveTable() {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");

  const { loadTicketList, ticketsList, ticketsLoading } = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions, dashboardFilterStatus } = useContext(TicketManagementContext);

  const searchtext = filterParams.searchText;

  useEffect(() => {
    if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
      const queryParams = new URLSearchParams(location);
      if (queryParams.has("status")) {
        queryParams.delete("status");
        history.replace({
          search: queryParams.toString(),
        });
      }
      loadTicketList("preventative");
    }
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    if (status && !dashboardFilterStatus) {
      loadTicketList("preventative", status, dashboard);
    }
  }, [pagination, searchtext]);



  return (
    <React.Fragment>
      <Head title="Pv-Plant" />
      <Content> <TicketListCompact ticketsList={ticketsList} viewingPage={"PREVENTATIVE"} /></Content>
    </React.Fragment>
  );
}

export default withTicketManagementProvider(PreventiveTable);
