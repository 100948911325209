import React, { useContext } from "react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import Logo from "../../images/bge_logo.png";
import LogoDark from "../../images/bge_logo.png";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useForm } from "react-hook-form";
import { Link,useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { AuthContext } from "../../providers/AuthContext.provider";
import { ResetPasswordContext, withResetPasswordProvider } from "./providers/ResetPasswordContext.provider";
import { CLIENT_LOGIN_PAGE } from "../../constants/routes";
import Swal from "sweetalert2";
import * as yup from "yup";
import { errorToast} from "../../components/toastify/Toastify";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";



const createSchema = yup.object({
  newPassword: yup
    .string()
    .required("New password is required")
    .min(8, "Passwords should fall within the range of 8 to 32 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .default(""),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .default(""),
});

const ResetPassword = () => {
  const history = useHistory();

  const location = useLocation().search;
  const   isNewUser = new URLSearchParams(location).get("isNewUser");

  const { resetPasswordToken } = useParams()
  const authContext = useContext(AuthContext)
  
  const resetPasswordContext = useContext(ResetPasswordContext)

  const { loading, setLoading } = resetPasswordContext
  const { passState, setPassState } = resetPasswordContext;
  const { errorVal, setError } = resetPasswordContext;

  const userFormRef = useForm({
    resolver: yupResolver(createSchema),
  });

  const confirmationSweetAlert = () => {
    Swal.fire({
        text: "Reset Password is successfull",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Redirect to Login Page",
    }).then((result) => {
        if (result.isConfirmed) {
          history.push(CLIENT_LOGIN_PAGE)
        }
      })
    }
    const { formState: {errors}, register, handleSubmit } = userFormRef;
  const onFormSubmit = async (formData) => {
    setLoading(true);
    try {
      if (formData.newPassword === formData.confirmPassword) {
        await authContext.onResetPassword(resetPasswordToken,{...formData, isNewUser})
        confirmationSweetAlert();
      } else if (formData.newPassword !== formData.confirmPassword) {
        errorToast({ description: "Both password should be same" });
      }
    } catch (e) {
      errorToast({ description: `${e}`});
    } 
    finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="ResetPassword" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Create New Password</BlockTitle>
                <BlockDes>
                  <p>Your New password must be different from previous used password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter" >
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    New Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#new-password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="new-password"
                    name="newPassword"
                    {...register("newPassword", { required: "This field is required" })}
                    placeholder="new password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                </div>
                {errors.newPassword?.message && (
                  <span style={{ color: "red" }} className="invalid">
                    {errors.newPassword?.message}
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="confirm-password">
                    Confirm Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type={"password"}
                    id="confirm-password"
                    name="confirmPassword"
                    {...register("confirmPassword", { required: "This field is required" })}
                    placeholder="confirm password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                </div>
                {errors.confirmPassword?.message && (
                  <span style={{ color: "red" }} className="invalid">
                    {errors.confirmPassword?.message}
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" onClick={handleSubmit(onFormSubmit)} color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Reset Password"}
                </Button>
              </FormGroup>
            </Form>
            {/* {renderFormCardFooter()} */}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment >
  );
};

export default withResetPasswordProvider(ResetPassword)