/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import StringList from "../components/StringList";
import { withStringManagementProvider, StringManagementContext } from "../StringProvider";
import { useLocation, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const StringListContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const { plantId } = useParams();
  const { loadStringList, loadFilterOptions } = useContext(StringManagementContext);
  const { pagination, filterParams, dashboardFilterStatus } = useContext(StringManagementContext);

  const searchtext = filterParams.searchText;

  useEffect(() => {
    if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
      const queryParams = new URLSearchParams(location);
      if (queryParams.has("status")) {
        queryParams.delete("status");
        history.replace({
          search: queryParams.toString(),
        });
      }
      if (queryParams.has("plantId")) {
        queryParams.delete("plantId");
        history.replace({
          search: queryParams.toString(),
        });
      }
      loadStringList(plantId);
    }
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);
  useEffect(() => {
    if (status && !dashboardFilterStatus) {
      loadStringList(plantId, status);
    }
  }, [pagination, searchtext]);

  return (
    <React.Fragment>
      <Head title="BGE | String Maintenance" />
      <Content>
        <div>
          <StringList />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default withStringManagementProvider(StringListContainer);
