/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import { useEffect } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Block,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
} from "../../../../components/Component";
import { RECORD_TYPE } from "../../constants.js";
import { EquipmentAndSpareContext } from "../../EquipmentSparesProvider";
import { CORRECTIVE_MAINTENANCE_PAGE, REMEDIAL_MAINTENANCE_PAGE } from "../../../../constants/routes.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const TransactionComponent = () => {
  const history = useHistory();

  const {
    transactions,
    isTransactionLoading,
    transactionPagination,
    loadClientTransaction,
    currentClientEquipment,
    setTransactionPagination,
  } = useContext(EquipmentAndSpareContext);

  useEffect(() => {
    if (currentClientEquipment.id) {
      loadClientTransaction(currentClientEquipment.id);
    }
  }, [currentClientEquipment, transactionPagination]);

  return (
    <>
      <Block>
        <DataTable className="card-stretch">
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <span className="sub-text">Record Date</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Record Type</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Cause</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="sub-text">Location</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Quantity</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Details</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Maintenance Ticket</span>
              </DataTableRow>
            </DataTableHead>
            {/*Head*/}
            {transactions?.items.length > 0 ? (
              transactions?.items.map((item) => {
                const recordType = RECORD_TYPE.find((d) => d.value === item.recordType).label;
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <span> {format(new Date(item.recordDate || null), "dd/MM/yyyy HH:mm ")}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span>{recordType}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.cause}</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>
                        {item.recordType === "TRANSFER"
                          ? item.fromWarehouse + ` -> ${item.toWarehouse}`
                          : item?.toWarehouse}
                      </span>{" "}
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.quantity}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.description}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      {item?.task?.identifier ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.replace(
                              item?.task?.category === "remedial"
                                ? REMEDIAL_MAINTENANCE_PAGE + `?id=${item?.task?.id}`
                                : CORRECTIVE_MAINTENANCE_PAGE + `?id=${item?.task?.id}`
                            );
                          }}
                        >
                          {item?.task?.identifier || "-"}
                        </span>
                      ) : (
                        <span>{"-"}</span>
                      )}{" "}
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>
          <div className="card-inner">
            {transactions?.pagination && transactions?.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={transactionPagination?.size}
                totalItems={transactions?.pagination?.totalCount}
                paginate={(page) => setTransactionPagination((p) => ({ ...p, page }))}
                paginatePage={(size) => setTransactionPagination((p) => ({ ...p, size, page: 1 }))}
                currentPage={transactions?.pagination?.currentPage}
              />
            ) : (
              !isTransactionLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {isTransactionLoading && <Skeleton count={10} className="w-100" />}
      </Block>
    </>
  );
};
export default TransactionComponent;
