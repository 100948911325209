export const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:4334";

export const CLIENT_LOGIN = `${BASE_URL}/api/client/sign-in`;
export const CLIENT_USER_LOOKUP = `${BASE_URL}/api/users/client/lookup`;

export const CLIENT_FORGOT_PASSWORD = `${BASE_URL}/api/forgot-password`;

//RESET_PASSWORD
export const CLIENT_RESET_PASSWORD = `${BASE_URL}/api/reset-password/:resetPasswordToken`;

export const EXPORT_CLIENT_TASK_API = `${BASE_URL}/api/client/all-tasks/export`;
export const EXPORT_CLIENT_PLANT_TASK_API = `${BASE_URL}/api/client/plant/all-tasks/export`;

export const EXPORT_CLIENT_PV_PLANT = `${BASE_URL}/api/client/pv-plant/export`;

export const CLIENT_PVPLANT_LIST = `${BASE_URL}/api/client/all-plants`;
export const CLIENT_PLANT_VIEW_API = `${BASE_URL}/api/client/plant/:plantId`;
export const CLIENT_PLANT_TASK_LIST = `${BASE_URL}/api/client/plant/tasks/:plantId`;
export const CLIENT_PLANT_TASK_VIEW = `${BASE_URL}/api/client/task/:taskId`;
export const CLIENT_ALL_PLANTS = `${BASE_URL}/api/client/plants-data`;
export const CLIENT_PLANT_TOTAL_TASKS = `${BASE_URL}/api/client/total-tasks`;
export const CLIENT_TASK_FILTER_OPTIONS = `${BASE_URL}/api/client/filter-options`;
export const CLIENT_PLANT_VIEW_TOTAL_TASKS_API = `${BASE_URL}/api/client/plant/:plantId/total-tasks`;
export const CLIENT_PVPLANT_FILTER_OPTIONS = `${BASE_URL}/api/client/pvplant-options`;

//CLIENT_USER_MANAGEMENT
export const CLIENT_USER_MANAGEMENT_PASSWORD_API = `${BASE_URL}/api/client/users/:userId/password`;
export const CLIENT_USER_MANAGEMENT_VIEW_API = `${BASE_URL}/api/client/users/:userId`;
export const CLIENT_USER_MANAGEMENT_UPDATE_API = `${BASE_URL}/api/client/users/update/:userId`;

export const CLIENT_TICKET_LIST = `${BASE_URL}/api/client/tasks`;

//CLIENT_STRING_MANAGEMENT
export const EXPORT_CLIENT_STRING_API = `${BASE_URL}/api/client/all-strings/export`;
export const CLIENT_STRING_LIST = `${BASE_URL}/api/client/strings`;
export const CLIENT_STRING_FILTER_OPTIONS = `${BASE_URL}/api/client/string/filter-options`;
export const CLIENT_STRING_VIEW_API = `${BASE_URL}/api/client/string/:stringId`;

//SPARE_PARTS
export const CLIENT_EQUIPMENT_SPARE_LIST_API = `${BASE_URL}/api/client/equipment-and-spares`;
export const EXPORT_CLIENT_EQUIPMENT_SPARE_API = `${BASE_URL}/api/client/equipment-and-spares/export`;
export const CLIENT_EQUIPMENT_SPARE_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/client/equipment-and-spares/filter-options`;
export const CLIENT_EQUIPMENT_SPARE_VIEW_API = `${BASE_URL}/api/client/equipment-and-spares/:equipmentId`;
export const STOCK_CLIENT_TRANSACTION_LIST_API =`${BASE_URL}/api/client/stock-transactions`

//GET_FILE
export const GET_FILE_API = `${BASE_URL}/api/files/:key`;

//GLOBAL_SEARCH
export const CLIENT_GLOBAL_SEARCH = `${BASE_URL}/api/client/global-search`
