
import { withHomeProvider ,HomeContext} from "./HomeProvider"
import React ,{useContext,useEffect} from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import HomePage from "./HomePage"

const HomeContainerPage = props => {
  const {loadClientAllPlants,loadClientTotalTasks} = useContext(HomeContext)

  useEffect(()=>{
    loadClientAllPlants();
    loadClientTotalTasks()
  },[])

  return <React.Fragment>
  <Head title="Bge Client" />
  <Content>
    <HomePage />
  </Content>
</React.Fragment>
}

export default withHomeProvider(HomeContainerPage)