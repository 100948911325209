export const HOME_PAGE = `${process.env.PUBLIC_URL}/`;

export const CLIENT_USER_PROFILE_PAGE = `${process.env.PUBLIC_URL}/client/user-profile`;
export const CLIENT_USER_PROFILE_SETTING = `${process.env.PUBLIC_URL}/client/user-profile-setting`;
export const CLIENT_USER_PROFILE_UPDATE = `${process.env.PUBLIC_URL}/client/user-profile-update`;

export const CLIENT_LOGIN_PAGE = `/auth-login`;

export const CLIENT_FORGOT_PASSWORD_PAGE = `/auth-forgot-password`;

export const CLIENT_RESET_PASSWORD_PAGE = `/auth-reset-password/:resetPasswordToken`;

export const CLIENT_PVPLANT_PAGE = `/client/pv-plants`;
export const CLIENT_PVPLANT_VIEW = `/client/pv-plant/:plantId`;

export const CLIENT_TICKET_LIST = `/client/maintenance`;

export const CLIENT_SPARE_PARTS = `/client/spare-parts`;

export const PUBLIC_CLIENT_RESET_PASSWORD = CLIENT_RESET_PASSWORD_PAGE.replace(":resetPasswordToken", "");

export const PUBLIC_PATH = [CLIENT_LOGIN_PAGE, CLIENT_FORGOT_PASSWORD_PAGE, PUBLIC_CLIENT_RESET_PASSWORD];

// --- maintenance subtask route ---

export const CORRECTIVE_MAINTENANCE_PAGE = `/client/maintenance/corrective-maintenance`;
export const MAINTENANCE_TICKET_VIEW_PAGE = `/client/maintenance/:ticketId`;

export const PREVENTATIVE_TASKS_PAGE = `/client/maintenance/preventive-maintenance`;

export const REMEDIAL_MAINTENANCE_PAGE = `/client/maintenance/remedial-maintenance`;

export const STRING_MAINTENANCE = `/client/maintenance/string-maintenance`;

export const EQUIPMENT_SPARE_VIEW = `/equipments-and-spares/:equipmentId`;

export const GLOBAL_SEARCH_MANAGEMENT_PAGE = `/client/globalSearch`;
