import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../providers/AuthContext.provider";

const PrivateRoute = ({ exact, component: Component, ...rest }) => {
  const authContext = useContext(AuthContext)
  const {currentUser, isAuthenticated} = authContext
  

  return <Route
    exact={exact ? true : false}
    rest
    render={(props) =>
      isAuthenticated && !currentUser?.resetPassword? (
        <Component {...props} {...rest}></Component>
      ) : (
        <Redirect to={`${process.env.PUBLIC_URL}/auth-login`}></Redirect>
      ) 
    }
  ></Route>
};

export default PrivateRoute;
