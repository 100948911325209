import apiService from "../../services/ApiService";
import {
  CLIENT_ALL_PLANTS,
  CLIENT_PLANT_TOTAL_TASKS
} from "../../constants/endpoints";

export const getClientPlants = async () => {
    const response = await apiService.get(CLIENT_ALL_PLANTS);
    return response;
  };
  export const getTotalTasks = async () => {
    const response = await apiService.get(CLIENT_PLANT_TOTAL_TASKS);
    return response;
  };
