import React, { useState } from 'react'

export const ResetPasswordContext = React.createContext()

export const ResetPasswordProvider = props => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");

  const mContext = {
    loading, setLoading,
    passState, setPassState,
    errorVal, setError
  }

  return <ResetPasswordContext.Provider value={mContext}>
    {props.children}
  </ResetPasswordContext.Provider>
}

export const withResetPasswordProvider = (Container, p) => props => <ResetPasswordProvider><Container {...props} /></ResetPasswordProvider>