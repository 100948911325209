import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilterBadge";
import Head from "../../../../layout/head/Head";
import ModalViewer from "../../../plant_management/ModalViewer";
import { StringManagementContext } from "../StringProvider";
import StringViewContainer from "../containers/StringViewContainer";
import ExportForm from "./ExportForm";
import StringListFilter from "./StringListFilter";
import { SORT_OPTION, statusColorTheme } from "./constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const StringListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const { plantId } = useParams();

  const stringManagementContext = useContext(StringManagementContext);
  const { stringsList, pagination, stringLoading } = stringManagementContext;
  const {
    filterParams,
    handleApplyFilter,
    sfState,
    showFilter,
    isFilterApplied,
    failureReason: failureReasons,
    taskStatuses,
    plants,
  } = stringManagementContext;

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [string, setString] = useState("");
  const [view, setView] = useState("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, order: sortOrder, sortBy: field });
  };
  const VIEW = {
    VIEW: "VIEW",
  };
  const viewTicket = (stringId) => {
    setString(stringId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return <StringViewContainer stringId={string} setIsOpen={setIsOpen} />;
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const onSuccessfulModal = () => {
    toggleExportModal();
  };

  const taskCurrentStatus = taskStatuses?.map((item) => {
    return item?.value;
  });
                                      
  const getExportComponent = () => {
    return (
      <ExportForm
        plantId={plantId}
        onSuccessfulModal={onSuccessfulModal}
        taskCurrentStatus={taskCurrentStatus}
        filterParams={filterParams}
        setIsExportModalOpen={setIsExportModalOpen}
      />
    );
  };
  const createExport = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const currentItems = stringsList?.items || <Skeleton count={10} />;
  const plantSelectedData = filterParams.selectedPlant.map((item, key) => {
    return plants?.[plants?.findIndex((status) => status.value === item)];
  });

  const assignedfailureReasonSelecterData = filterParams.selectedFailureReason.map((item, key) => {
    return failureReasons?.[failureReasons?.findIndex((status) => status.value === item)];
  });
  const selectedSortByData = SORT_OPTION.find((p) => p.value === filterParams.sortBy);

  return (
    <React.Fragment>
      <Head title="Brighter-App | String Maintenance" />

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" className={"mt-5"}>
              Strings Lists
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {stringsList?.pagination?.totalCount} Strings.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedStatus} title={"Status"} isMap={false} isStr={false} />

              {plantSelectedData ? (
                <FilteredByList data={plantSelectedData} title={"Plant Name"} isMap={true} isStr={false} />
              ) : null}

              {assignedfailureReasonSelecterData ? (
                <FilteredByList
                  data={assignedfailureReasonSelecterData}
                  title={"failure Reason"}
                  isMap={true}
                  isStr={false}
                />
              ) : null}

              {selectedSortByData ? (
                <FilteredByList data={selectedSortByData.label} title={"Sort By"} isNotMap={true} isStr={true} />
              ) : null}

              {filterParams.investigatedAt ? (
                <FilteredByList
                  data={filterParams.investigatedAt}
                  title={"Investigated at"}
                  isNotMap={false}
                  isStr={true}
                />
              ) : null}

              {filterParams.addedAt ? (
                <FilteredByList data={filterParams.addedAt} title={"Added at"} isNotMap={false} isStr={true} />
              ) : null}
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <Button
                      className={`btn btn-white btn-outline-light ${currentItems.length > 0 ? "" : "disabled"}`}
                      type="export"
                      isLoading={exportLoading}
                      onClick={createExport}
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <div className="toggle-wrap">
                      <Button
                        className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                        onClick={() => updateTableSm(true)}
                      >
                        <Icon name="menu-right"></Icon>
                      </Button>
                      <div tag="a" className="btn btn-icon " onClick={sfState.toggle}>
                        {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                        <Icon name="filter-alt"></Icon>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by identifier and plant name. Enter to search "
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                        ? handleNameSort("DESC", "identifier")
                        : handleNameSort("ASC", "identifier");
                    }}
                  >
                    Identifier
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "identifier"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                        ? handleNameSort("DESC", "identifier")
                        : handleNameSort("ASC", "identifier");
                    }}
                  >
                    Plant Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "plantId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plantId"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "plantId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "plantId"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                        ? handleNameSort("DESC", "status")
                        : handleNameSort("ASC", "status");
                    }}
                  >
                    Status{" "}
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "status"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "addedAt"
                        ? handleNameSort("DESC", "addedAt")
                        : handleNameSort("ASC", "addedAt");
                    }}
                  >
                    Added At
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "addedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "addedAt"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "addedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "addedAt"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "investigatedAt"
                        ? handleNameSort("DESC", "investigatedAt")
                        : handleNameSort("ASC", "investigatedAt");
                    }}
                  >
                    Investigated At
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "investigatedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "investigatedAt"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "investigatedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "investigatedAt"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "repairedAt"
                        ? handleNameSort("DESC", "repairedAt")
                        : handleNameSort("ASC", "repairedAt");
                    }}
                  >
                    Repaired At
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "repairedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "repairedAt"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "repairedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "repairedAt"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "failureReasonId"
                        ? handleNameSort("DESC", "failureReasonId")
                        : handleNameSort("ASC", "failureReasonId");
                    }}
                  >
                    Failure Reason
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "failureReasonId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "failureReasonId"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "failureReasonId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "failureReasonId"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "workComments"
                        ? handleNameSort("DESC", "workComments")
                        : handleNameSort("ASC", "workComments");
                    }}
                  >
                    Work Comments
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "workComments");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "workComments"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "workComments");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "workComments"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "monitoringComments"
                        ? handleNameSort("DESC", "monitoringComments")
                        : handleNameSort("ASC", "monitoringComments");
                    }}
                  >
                    Monitoring Comments
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "monitoringComments");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "monitoringComments"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "monitoringComments");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "monitoringComments"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "noOfStringsPerDuct"
                        ? handleNameSort("DESC", "noOfStringsPerDuct")
                        : handleNameSort("ASC", "noOfStringsPerDuct");
                    }}
                  >
                    No Of Strings Per Duct
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "noOfStringsPerDuct");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "noOfStringsPerDuct"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "noOfStringsPerDuct");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "noOfStringsPerDuct"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
            </DataTableHead>
            {/*Head*/}

            <Sidebar toggleState={showFilter}>
              <StringListFilter failureReason={failureReasons} plants={plants} taskStatuses={taskStatuses} />
            </Sidebar>

            {stringLoading ? (
              <></>
            ) : currentItems.length > 0 ? (
              currentItems.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <div className="user-card">
                        <div
                          className="user-card dropdown-toggle"
                          onClick={() => {
                            viewTicket(item.id);
                          }}
                        >
                          <div className="user-name">
                            <span
                              data-toggle="tooltip"
                              title={item.title}
                              style={{
                                width: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              className="tb-lead"
                            >
                              {item?.identifier || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </DataTableRow>

                    <DataTableRow size="sm">
                      <span>{item.plant?.name || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <Badge className="text-capitalize" style={statusColorTheme[item.status]} pill>
                        <span>{item?.status === "VALIDATE" ? "READY FOR APPROVAL" : item?.status || "N/A"}</span>
                      </Badge>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>
                        {item?.addedAt ? format(new Date(item.addedAt ? item.addedAt : null), "dd MMM yyyy") : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>
                        {item?.investigatedAt
                          ? format(new Date(item.investigatedAt ? item.investigatedAt : null), "dd MMM yyyy")
                          : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>
                        {item?.repairedAt
                          ? format(new Date(item.repairedAt ? item.repairedAt : null), "dd MMM yyyy")
                          : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span> {item?.failureReason?.name || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span> {item?.workComments || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span> {item?.monitoringComments || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span> {item?.noOfStringsPerDuct || "-"}</span>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>
          {stringLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {stringsList && stringsList.pagination && stringsList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={stringsList.pagination.totalCount}
                paginate={stringManagementContext.updateShowListPage}
                currentPage={stringsList.pagination.currentPage}
                paginatePage={stringManagementContext.updateShowListSize}
              />
            ) : (
              !stringLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="String Lists"
        isOpen={isOpen}
        size="xl"
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="String Lists - Select the Export Fields"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default StringListCompact;
