/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import download from "js-file-download";
import React, { useContext, useState } from "react";
import { CardBody, CardText, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Block, Button, PreviewCard } from "../../../components/Component";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { EquipmentAndSpareContext } from "../EquipmentSparesProvider";
import { exportClientEquipmentAPI } from "../EquipmentSparesRepository";

const ExportComponent = (props) => {
  const { filterParams, isExportLoading } = useContext(EquipmentAndSpareContext);
  const TASK_FIELDS = [
    {
      label: "Equipment Name",
      value: "equipmentName",
    },
    {
      label: "Plant Name",
      value: "plantName",
    },
    {
      label: "Supplier",
      value: "supplier",
    },
    {
      label: "Asset Category",
      value: "category",
    },
    {
      label: "Total Spares",
      value: "quantity",
    },
    {
      label: "Contractual Quantity",
      value: "contractualQuantity",
    },
    {
      label: "Is Key Component",
      value: "isKeyComponent",
    },
    {
      label: "Description",
      value: "description",
    },
    {
      label: "Installed Quantity",
      value: "installedQuantity",
    },
    {
      label: "Spare Quantity",
      value: "spareQuantity",
    },
    {
      label: "External Warehouse Quantity",
      value: "externalWarehouseQuantity",
    },
    {
      label: "Bge Warehouse Quantity",
      value: "bgeWarehouseQuantity",
    },
    {
      label: "Supplier premises Quantity",
      value: "supplierPremisesQuantity",
    },
    {
      label: "Part Number",
      value: "partNumber",
    },
  ];

  const [value, setValue] = useState(
    TASK_FIELDS.map((plant) => {
      return plant.value;
    })
  );

  const handleFormSubmit = async (formData) => {
    try {
      if (props?.plantIds) {
        filterParams["plant"] = props?.plantIds;
      }
      const response = await exportClientEquipmentAPI({ ...filterParams, headerData: value });
      download(response, `spare parts.csv`);
      props.setIsExportModalOpen(true);
      successToast({ description: "Successfully Exported" });
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button color="primary" size="md" className="mt-1" isLoading={isExportLoading} onClick={handleFormSubmit}>
        Export Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0">
                {TASK_FIELDS &&
                  TASK_FIELDS.map((task, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${task} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0">
                              <input
                                key={`${task}-${key}`}
                                type="checkbox"
                                checked={value.includes(task.value)}
                                id={`${task}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([task.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === task.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${task}-${key}`} check>
                                {task.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};
export default ExportComponent;
