import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import { format } from "date-fns";
import { getAllStrings, getClientString, getStringListFilterOptions } from "./StringRepository";

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterParams = {
  selectedStatus: [],
  selectedPlant: [],
  searchText: "",
  addedAt: "",
  investigatedAt: "",
  selectedFailureReason: [],
  sortBy: "",
  order: "",
};

const initialFilterOptions = {
  plants: [],
  failureReason: [],
};

export const StringManagementContext = React.createContext();

export const StringManagementProvider = (props) => {
  const [stringsList, setStringsList] = React.useState([]);
  const [dashboardFilterStatus, setDashboardFilterStatus] = useBoolean(false);

  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filtersLoading, flState] = useBoolean(false);
  const [currentViewString, setCurrentViewString] = React.useState(null);
  const [plants, setPlants] = React.useState([]);
  const [failureReason, setFailureReason] = React.useState([]);
  const [taskStatuses, setTaskStatuses] = React.useState([]);

  //Loaders
  const [stringLoading, slState] = useBoolean(false);

  //filter
  const [showFilter, sfState] = useBoolean(false);

  const getStringListFilterParams = () => {
    const queryParams = { ...pagination };
    const { selectedStatus, selectedPlant, searchText, addedAt, investigatedAt, selectedFailureReason, sortBy, order } =
      filterParams;
    if (selectedStatus.length) queryParams["selectedStatus"] = selectedStatus;
    if (selectedPlant.length) queryParams["selectedPlant"] = selectedPlant;
    if (selectedFailureReason.length) queryParams["selectedFailureReason"] = selectedFailureReason;
    if (searchText) queryParams["searchText"] = searchText;
    if (investigatedAt) queryParams["investigatedAt"] = investigatedAt
    if (addedAt) queryParams["addedAt"] = addedAt
    if (sortBy) queryParams["sortBy"] = sortBy;
    if (order) queryParams["order"] = order;
    return queryParams;
  };

  const loadStringList = async (plantId, status) => {
    slState.on();
    if (status) {
      handleApplyFilter({ ...filterParams, selectedStatus: [status] });
    }
    try {
      const filterParams = getStringListFilterParams();
      if (status) {
        filterParams.selectedStatus = status;
      }
      if (plantId) {
        filterParams.selectedPlant = plantId;
      }
      const response = await getAllStrings(filterParams);
      setStringsList(response.stringList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      slState.off();
    }
  };

  // VIEW TICKET
  const loadClientString = async (stringId) => {
    try {
      const response = await getClientString(stringId);
      setCurrentViewString(response.string);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const loadFilterOptions = async () => {
    flState.on();
    try {
      const response = await getStringListFilterOptions();
      setPlants(response.plants);
      setFailureReason(response.failureReason);
      setTaskStatuses(response.taskStatuses);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const resetPvPlantListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const checkIsFilterApplied = () => {
    if (
      filterParams.selectedStatus.length != 0 ||
      filterParams.selectedPlant != 0 ||
      filterParams.investigatedAt ||
      filterParams.sortBy ||
      filterParams.order ||
      filterParams.addedAt
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isFilterApplied = checkIsFilterApplied();

  const mContext = {
    isFilterApplied,
    checkIsFilterApplied,
    loadStringList,
    loadClientString,
    stringLoading,
    pagination,
    stringsList,
    showFilter,
    sfState,
    handleApplyFilter,
    resetPvPlantListFilter,
    currentViewString,
    filtersLoading,
    plants,
    failureReason,
    filterParams,
    loadFilterOptions,
    taskStatuses,
    slState,
    setDashboardFilterStatus,
    dashboardFilterStatus,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <StringManagementContext.Provider value={mContext}>{props.children}</StringManagementContext.Provider>;
};
export const withStringManagementProvider = (Container, containerProps) => (props) =>
  (
    <StringManagementProvider>
      <Container {...containerProps} {...props} />
    </StringManagementProvider>
  );
