import React, { useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { DropdownItem, FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../components/Component";

import "react-datepicker/dist/react-datepicker.css";
import { sortOption } from "../../../../utils/Utils";
import { StringManagementContext } from "../StringProvider";
import { ORDER_OPTION, SORT_OPTION, STRING_FILTER } from "./constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const StringListFilter = (props) => {
  const stringManagementContext = useContext(StringManagementContext);
  const { plants, failureReason, taskStatuses } = props;
  const { plantId } = useParams();
  const { pagination } = stringManagementContext;
  const {
    filterParams,
    handleApplyFilter,
    setDashboardFilterStatus,
    sfState,
    showFilter,
    checkIsFilterApplied,
    resetPvPlantListFilter,
  } = stringManagementContext;
  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const itemPerPage = pagination.size;

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  useEffect(() => {
    const filters = localStorage.getItem(STRING_FILTER);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
        addedAt: parseFilters.addedAt ? new Date(parseFilters.addedAt) : "",
        investigatedAt: parseFilters.investigatedAt ? new Date(parseFilters.investigatedAt) : "",
      });
    }
  }, []);
  const handleReset = () => {
    resetPvPlantListFilter();
    localStorage.removeItem(STRING_FILTER);
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    setDashboardFilterStatus.on();
    checkIsFilterApplied();
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleStateFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedStatus: statusFilter });
  };

  const handleFailureReasonSelection = (selectedOption) => {
    const selected = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedFailureReason: selected });
  };
  const handlePlantSelection = (selectedOption) => {
    const assignedFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedPlant: assignedFilter });
  };
  const handleSortBy = (selectedOption) => {
    const result = selectedOption.value;
    handleFilterSelection({ sortBy: result });
  };
  const handleOrder = (selectedOption) => {
    const result = selectedOption.value;
    handleFilterSelection({ order: result });
  };
  const handleaddedAtSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ addedAt: "" });
    } else {
      handleFilterSelection({ addedAt: selectedOption });
    }
  };

  const handleinvestigatedAtSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ investigatedAt: "" });
    } else {
      handleFilterSelection({ investigatedAt: selectedOption });
    }
  };

  const renderShowOptions = (showValue = 5) => {
    return (
      <li key={`filter-pagination-${showValue}`} className={itemPerPage === showValue ? "active" : ""}>
        <DropdownItem
          tag="a"
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            stringManagementContext.updateShowListSize(showValue);
          }}
        >
          {showValue}
        </DropdownItem>
      </li>
    );
  };
  const statusSelectedFilter = selectedFilter.selectedStatus.map((item, key) => {
    return taskStatuses?.[taskStatuses?.findIndex((status) => status.value === item)];
  });

  const assignedfailureReasonSelectedFilter = selectedFilter.selectedFailureReason.map((item, key) => {
    return failureReason?.[failureReason?.findIndex((status) => status.value === item)];
  });

  const plantSelectedFilter = selectedFilter.selectedPlant.map((item, key) => {
    return plants?.[plants?.findIndex((status) => status.value === item)];
  });
  const selectedSortBy = SORT_OPTION.find((p) => p.value === selectedFilter.sortBy);
  const selectedOrder = ORDER_OPTION.find((p) => p.value === selectedFilter.order);

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={taskStatuses}
                  placeholder="Any Status"
                  onChange={handleStateFilterSelection}
                  isMulti
                  value={statusSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Failure Reason</label>
                <RSelect
                  options={sortOption(failureReason)}
                  placeholder="Any Failure Reason"
                  onChange={handleFailureReasonSelection}
                  isMulti
                  value={assignedfailureReasonSelectedFilter}
                />
              </FormGroup>
            </Col>
            {plantId ? null : (
              <Col size="12">
                <FormGroup>
                  <label className="overline-title overline-title-alt">Plant Name</label>
                  <RSelect
                    options={sortOption(plants)}
                    placeholder="Any Plant"
                    onChange={handlePlantSelection}
                    isMulti
                    value={plantSelectedFilter}
                  />
                </FormGroup>
              </Col>
            )}

            <Col size="6">
              <label className="overline-title overline-title-alt">Order</label>
              <RSelect options={ORDER_OPTION} placeholder="Order" onChange={handleOrder} value={selectedOrder} />
            </Col>
            <Col size="6">
              <label className="overline-title overline-title-alt">Sort By</label>
              <RSelect
                options={sortOption(SORT_OPTION)}
                placeholder="Sort by"
                onChange={handleSortBy}
                value={selectedSortBy}
              />
            </Col>
            <Col size="6">
              <FormGroup>
                <label className="overline-title overline-title-alt">Added At Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.addedAt || null}
                  onChange={(date) => handleaddedAtSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <FormGroup>
                <label className="overline-title overline-title-alt">Investigated At Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.investigatedAt || null}
                  onChange={(date) => handleinvestigatedAtSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default StringListFilter;
