/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewString from "../components/StringView";
import { StringManagementContext, withStringManagementProvider } from "../StringProvider";

const StringViewContainer = (props) => {
  const { stringId, setIsOpen } = props;

  const stringManagementContext = useContext(StringManagementContext);
  const { loadClientString } = stringManagementContext;

  useEffect(() => {
    if (stringId) loadClientString(stringId);
  }, [stringId]);

  return (
    <React.Fragment>
      <ViewString mode={props.mode} setIsOpen={setIsOpen} />
    </React.Fragment>
  );
};

export default withStringManagementProvider(StringViewContainer);
