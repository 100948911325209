import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import { GlobalSearchContext } from "../../providers/GlobalSearchProvider";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const GlobalSearchListCompact = () => {
  const location = useLocation().search;
  const searchParams = new URLSearchParams(location).get("searchText");
  const globalSearchContext = useContext(GlobalSearchContext);

  const { globalData, totalCount, globalSearchLoading } = globalSearchContext;

  const currentItems = globalData || <Skeleton count={10} />;

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Showing result for "{searchParams}"
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {totalCount} search results.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      {globalSearchLoading ? (
        <></>
      ) : (
        currentItems.map((item) => {
          return (
            <Card className="mb-3 p-3">
              <Row md="12" lg="12">
                {item?.group === "Plant" && (
                  <>
                    <Col md="6" lg="6">
                      <div>
                        Plant Name:
                        <Link to={`${item?.link}`}>
                          <span className="tb-lead ml-1 font-weight-bolder">{item?.label}</span>
                        </Link>
                      </div>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Acronomy: {item?.data?.acronomy || "N/A"}</span>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Size: {item?.data?.size || "N/A"}</span>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">PostalCode: {item?.data?.postalCode || "N/A"}</span>
                    </Col>
                  </>
                )}
                {item?.group === "Client" && (
                  <>
                    <Col md="6" lg="6">
                      <div>
                        Client Name:
                        <Link to={`${item?.link}`}>
                          <span className="tb-lead ml-1 font-weight-bolder">{item?.label}</span>
                        </Link>
                      </div>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Plant Count: {item?.data?.plantCount || "N/A"}</span>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Email: {item?.data?.email || "N/A"}</span>
                    </Col>
                  </>
                )}
                {item?.group === "User" && (
                  <>
                    <Col md="6" lg="6">
                      <div>
                        User Name:
                        <Link to={`${item?.link}`}>
                          <span className="tb-lead ml-1 font-weight-bolder"> {item?.label}</span>
                        </Link>
                      </div>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Email: {item?.data?.email || "N/A"}</span>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Status: {item?.data?.status || "N/A"}</span>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">
                        Status:{" "}
                        {item?.data?.roles
                          ?.map((role) => {
                            return role;
                          })
                          .join(", ") || "N/A"}
                      </span>
                    </Col>
                  </>
                )}
                {item?.group === "Task" && (
                  <>
                    {item?.label && (
                      <Col md="6" lg="6">
                        <div>
                          Ticket Name:
                          <Link to={`${item?.link}`}>
                            <span className="tb-lead ml-1 font-weight-bolder">{item?.label}</span>
                          </Link>
                        </div>
                      </Col>
                    )}
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Type of Maintenance: {item?.category || "N/A"}</span>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Ticket Identifier: {item?.data?.taskIdentifier || "N/A"}</span>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Status: {item?.data?.status || "N/A"}</span>
                    </Col>
                  </>
                )}
                {item?.group === "Stock" && (
                  <>
                    <Col md="6" lg="6">
                      <div>
                        Product Id:
                        <Link to={`${item?.link}`}>
                          <span className="tb-lead ml-1 font-weight-bolder">{item?.value}</span>
                        </Link>
                      </div>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Total Spares: {item?.data?.totalSpares || "N/A"}</span>
                    </Col>
                    <Col md="6" lg="6">
                      <div>
                        Equipment Name:
                        <span className="tb-lead ml-1 font-weight-bolder">{item?.label}</span>
                      </div>
                    </Col>
                    <Col md="6" lg="6">
                      <span className="tb-lead ">Owned To: {item?.data?.ownership || "N/A"}</span>
                    </Col>
                  </>
                )}
              </Row>
            </Card>
          );
        })
      )}
      {!globalSearchLoading && currentItems.length === 0 && (
        <div className="text-center">
          <span className="text-silent">No data found</span>
        </div>
      )}
      {globalSearchLoading && <Skeleton count={10} className="w-100" />}
    </React.Fragment>
  );
};
export default GlobalSearchListCompact;
