import apiService from "../../../services/ApiService";
import {
  CLIENT_STRING_FILTER_OPTIONS,
  CLIENT_STRING_LIST,
  CLIENT_STRING_VIEW_API,
  EXPORT_CLIENT_STRING_API,
} from "../../../constants/endpoints";

export const getAllStrings = async (filterParams) => {
  const response = await apiService.get(CLIENT_STRING_LIST, {
    queryParams: filterParams,
  });
  return response;
};

export const exportClientString = async (filterParams) => {
  const response = await apiService.get(EXPORT_CLIENT_STRING_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};

export const getClientString = async (stringId) => {
  const response = await apiService.get(CLIENT_STRING_VIEW_API, {
    pathParams: { stringId },
  });
  return response;
};

export const getStringListFilterOptions = async () => {
  const response = await apiService.get(CLIENT_STRING_FILTER_OPTIONS);
  return response;
};
export const exportString = async (filterParams) => {
  const response = await apiService.get(EXPORT_CLIENT_STRING_API, {
      queryParams: filterParams,
  })
  return response
}
