import {
  HOME_PAGE,
  CLIENT_PVPLANT_PAGE,
  CLIENT_TICKET_LIST,
  CLIENT_SPARE_PARTS,
  CORRECTIVE_MAINTENANCE_PAGE,
  PREVENTATIVE_TASKS_PAGE,
  REMEDIAL_MAINTENANCE_PAGE,
  STRING_MAINTENANCE,
} from "../../constants/routes";

const menu = [
  {
    icon: "home-fill",
    text: "Home",
    link: HOME_PAGE,
  },
  {
    icon: "spark-fill",
    text: "PV Plant",
    link: CLIENT_PVPLANT_PAGE,
  },
  {
    icon: "clipboad-check",
    text: "Maintenance",
    link: CLIENT_TICKET_LIST,
    active: false,
    subMenu: [
      {
        text: "Corrective Maintenance",
        link: CORRECTIVE_MAINTENANCE_PAGE,
      },
      {
        text: "Preventative Maintenance",
        link: PREVENTATIVE_TASKS_PAGE,
      },
      {
        text: "Remedial Maintenance",
        link: REMEDIAL_MAINTENANCE_PAGE,
      },
      {
        text: "String Maintenance",
        link: STRING_MAINTENANCE,
      },
    ],
  },
  {
    icon: "cpu",
    text: "Equipment And Spares",
    link: CLIENT_SPARE_PARTS,
  },
  // {
  //   text: "Pages",
  //   subMenu: [
  //     {
  //       text: "Projects",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Project Cards",
  //           link: "/project-card",
  //         },
  //         {
  //           text: "Project List",
  //           link: "/project-list",
  //         },
  //       ],
  //     },
  //     {
  //       text: "User Manage",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "User List - Regular",
  //           link: "/user-list-regular",
  //         },
  //         {
  //           text: "User List - Compact",
  //           link: "/user-list-compact",
  //         },
  //         {
  //           text: "User Details - Regular",
  //           link: "/user-details-regular/1",
  //         },
  //         {
  //           text: "User Profile - Regular",
  //           link: "/user-profile-regular",
  //         },
  //         {
  //           text: "User Contact - Card",
  //           link: "/user-contact-card",
  //         },
  //       ],
  //     },
  //     {
  //       text: "AML / KYCs",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "KYC List - Regular",
  //           link: "/kyc-list-regular",
  //         },
  //         {
  //           text: "KYC Details - Regular",
  //           link: "/kyc-details-regular/UD01544",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Transaction",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Trans List - Basic",
  //           link: "/transaction-basic",
  //         },
  //         {
  //           text: "Trans List - Crypto",
  //           link: "/transaction-crypto",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Products",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Product List",
  //           link: "/product-list",
  //         },
  //         {
  //           text: "Product Card",
  //           link: "/product-card",
  //         },
  //         {
  //           text: "Product Details",
  //           link: "/product-details/0",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Invoice",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Invoice List",
  //           link: "/invoice-list",
  //         },
  //         {
  //           text: "Invoice Details",
  //           link: "/invoice-details/1",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Pricing Table",
  //       link: "/pricing-table",
  //     },
  //     {
  //       text: "Image Gallery",
  //       link: "/image-gallery",
  //       badge: "new",
  //     },
  //     {
  //       text: "Regular Page - v1",
  //       link: "/pages/regular-v1",
  //     },
  //     {
  //       text: "Regular Page - v2",
  //       link: "/pages/regular-v2",
  //     },
  //     {
  //       text: "Faqs / Help",
  //       link: "/pages/faq",
  //     },
  //   ],
  // },
  // {
  //   text: "Misc",
  //   subMenu: [
  //     {
  //       text: "Auth Pages",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Login / Signin",
  //           link: "/auth-login",
  //           newTab: true,
  //         },
  //         {
  //           text: "Register / Signup",
  //           link: "/auth-register",
  //           newTab: true,
  //         },
  //         {
  //           text: "Forgot Password",
  //           link: "/auth-reset",
  //           newTab: true,
  //         },
  //         {
  //           text: "Success / Confirm",
  //           link: "/auth-success",
  //           newTab: true,
  //         },
  //       ],
  //     },
  //     {
  //       text: "Error Pages",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "404 Classic",
  //           link: "/errors/404-classic",
  //           newTab: true,
  //         },
  //         {
  //           text: "504 Classic",
  //           link: "/errors/504-classic",
  //           newTab: true,
  //         },
  //         {
  //           text: "404 Modern",
  //           link: "/errors/404-modern",
  //           newTab: true,
  //         },
  //         {
  //           text: "504 Modern",
  //           link: "/errors/504-modern",
  //           newTab: true,
  //         },
  //       ],
  //     },

  //     {
  //       text: "Blank / Startup",
  //       link: "/_blank",
  //     },
  //     {
  //       text: "Terms / Policy",
  //       link: "/pages/terms-policy",
  //     },
  //   ],
  // },
  // {
  //   text: "Components",
  //   subMenu: [
  //     {
  //       text: "Ui Elements",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Alerts",
  //           link: "/components/alerts",
  //         },
  //         {
  //           text: "Accordions",
  //           link: "/components/accordions",
  //         },
  //         {
  //           text: "Badges",
  //           link: "/components/badges",
  //         },
  //         {
  //           text: "Buttons",
  //           link: "/components/buttons",
  //         },
  //         {
  //           text: "Button Group",
  //           link: "/components/button-group",
  //         },
  //         {
  //           text: "Breadcrumbs",
  //           link: "/components/breadcrumbs",
  //         },
  //         {
  //           text: "Cards",
  //           link: "/components/cards",
  //         },
  //         {
  //           text: "Carousel",
  //           link: "/components/carousel",
  //         },
  //         {
  //           text: "Dropdowns",
  //           link: "/components/dropdowns",
  //         },
  //         {
  //           text: "Modals",
  //           link: "/components/modals",
  //         },
  //         {
  //           text: "Pagination",
  //           link: "/components/pagination",
  //         },
  //         {
  //           text: "Popovers",
  //           link: "/components/popovers",
  //         },
  //         {
  //           text: "Progress",
  //           link: "/components/progress",
  //         },
  //         {
  //           text: "Spinner",
  //           link: "/components/spinner",
  //         },
  //         {
  //           text: "Tabs",
  //           link: "/components/tabs",
  //         },
  //         {
  //           text: "Toast",
  //           link: "/components/toast",
  //         },
  //         {
  //           text: "Tooltips",
  //           link: "/components/tooltips",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Utilities",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Borders",
  //           link: "/components/util-border",
  //         },
  //         {
  //           text: "Colors",
  //           link: "/components/util-colors",
  //         },
  //         {
  //           text: "Display",
  //           link: "/components/util-display",
  //         },
  //         {
  //           text: "Embeded",
  //           link: "/components/util-embeded",
  //         },
  //         {
  //           text: "Flex",
  //           link: "/components/util-flex",
  //         },
  //         {
  //           text: "Text",
  //           link: "/components/util-text",
  //         },
  //         {
  //           text: "Sizing",
  //           link: "/components/util-sizing",
  //         },
  //         {
  //           text: "Spacing",
  //           link: "/components/util-spacing",
  //         },
  //         {
  //           text: "Others",
  //           link: "/components/util-others",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Crafted icons",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "SVG Icon - Exclusive",
  //           link: "/svg-icons",
  //         },
  //         {
  //           text: "Nioicon - HandCrafted",
  //           link: "/nioicon",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Tables",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Basic Tables",
  //           link: "/table-basic",
  //         },
  //         {
  //           text: "Special Tables",
  //           link: "/table-special",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Forms",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Form Elements",
  //           link: "/components/form-elements",
  //         },
  //         {
  //           text: "Form Layouts",
  //           link: "/components/form-layouts",
  //         },
  //         {
  //           text: "Form Validation",
  //           link: "/components/form-validation",
  //         },
  //         {
  //           text: "Date Time Picker",
  //           link: "/components/datetime-picker",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Charts",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Chart Js",
  //           link: "/charts/chartjs",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Email Template",
  //       link: "/email-template",
  //       active: "false",
  //     },
  //   ],
  // },
];
export default menu;
