import React, { useEffect } from "react";
import {FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../components/Component";
import { PvPlantContext } from "../PvPlantProvider";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { TICKET_FILTERS } from "../../../constants/PreferenceKeys";

const PlantTicketListFilter = (props) => {
  const pvPlantContext = React.useContext(PvPlantContext);
  const { filterOptions } = props;
  const { filterParams, handleApplyFilter, sfState, showFilter } = pvPlantContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    pvPlantContext.resetPvPlantListFilter();
    localStorage.removeItem(TICKET_FILTERS);
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleStateFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedTaskStatus: statusFilter });
  };

  const handleArchiveFilter = (e) => {
    const isChecked = e.target.checked;
    handleFilterSelection({ isArchiveSelected: isChecked });
  };

  const handleStartDateSelection = (selectedOption) => {
    handleFilterSelection({ startDate: selectedOption });
  };

  const handleResolvedDateSelection = (selectedOption) => {
    handleFilterSelection({ resolvedDate: selectedOption });
  };

  const statusSelectedFilter = selectedFilter.selectedTaskStatus.map((item, key) => {
    return filterOptions.taskStatuses?.[filterOptions.taskStatuses?.findIndex((status) => status.value === item)];
  });

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={filterOptions.taskStatuses}
                  placeholder="Any Status"
                  onChange={handleStateFilterSelection}
                  isMulti
                  value={statusSelectedFilter}
                />
              </FormGroup>
            </Col>

            <Col size="6">
              <FormGroup>
                <label className="overline-title overline-title-alt">Start Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.startDate || null}
                  onChange={(date) => handleStartDateSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <FormGroup>
                <label className="overline-title overline-title-alt">Resolved Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.resolvedDate || null}
                  onChange={(date) => handleResolvedDateSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <div className="custom-control custom-control-sm custom-checkbox" style={{ zIndex: 0 }}>
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id={`isChecked_${props?.checkBox}`}
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor={`isChecked_${props?.checkBox}`}>
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className={"d-flex justify-content-between"} size="12">
              <Button
                href="#reset"
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default PlantTicketListFilter;
