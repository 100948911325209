/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewRemedialTask from "../components/RemedialTaskView";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";

const RemedialTaskViewContainer = props => {
    const { taskId, setIsOpen} = props

    const pvPlantContext = useContext(PvPlantContext)
    const { loadPvPlantTask } =pvPlantContext

    useEffect(() => {
        if (taskId) loadPvPlantTask(taskId)
    }, [taskId])

    return (
        <React.Fragment>
            <ViewRemedialTask setIsOpen={setIsOpen}  />
        </React.Fragment>
    );
}

export default withPvPlantProvider(RemedialTaskViewContainer);