/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewTicket from "../components/TicketView";
import { TicketManagementContext, withTicketManagementProvider } from "../TicketManagementProvider";
import Head from "../../../layout/head/Head";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { selectedMaintenanceTab } from "../../../constants/common";

const TicketViewContainer = (props) => {
  const { ticketId, setIsOpen, isPreventativeMaintenance } = props;

  const ticketManagementContext = useContext(TicketManagementContext);
  const { loadPvPlantTicket } = ticketManagementContext;

  useEffect(() => {
    if (ticketId) loadPvPlantTicket(ticketId);
  }, [ticketId]);

  const { pathname } = useLocation();

  const selectedTab = pathname.split("/");

  const tabName = selectedMaintenanceTab[selectedTab[selectedTab.length - 1]];
  return (
    <React.Fragment>
      <Head
        title={
          tabName === "Corrective Maintenance Tickets"
            ? "Brighter-App | Corrective Maintenance | View"
            : tabName === "Remedial Maintenance Tickets"
            ? "Brighter-App | Remedial Maintenance | View"
            : "Brighter-App |  Preventative Maintenance | View"
        }
      />
      <ViewTicket isPreventativeMaintenance={isPreventativeMaintenance} setIsOpen={setIsOpen} />
    </React.Fragment>
  );
};

export default withTicketManagementProvider(TicketViewContainer);
