/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect } from "../../../components/Component";
import { UserManagementContext } from "../UserManagementProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../hooks/useBoolean";
import { useHistory } from "react-router";
import { CLIENT_USER_PROFILE_PAGE } from "../../../constants/routes";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { phoneCode } from "../../../constants/country";

const createSchema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),

    phoneNumber: yup
      .number()
      .min(999999, "Mobile Number must be greater than 6")
      .max(9999999999999999, "Mobile Number must be less than 16")
      .notRequired()
      .typeError("Please enter a valid number"),
    mobileNumber: yup
      .number()
      .min(999999, "Mobile Number must be greater than 6")
      .max(9999999999999999, "Mobile Number must be less than 16")
      .notRequired()
      .typeError("Please enter a valid number"),
    phoneCode: yup.string().notRequired().default(`+44`),
    mobileCode: yup.string().notRequired().default(`+44`),
  })
  .required();

const updateSchema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup
      .number()
      .min(999999, "Mobile Number must be greater than 6")
      .max(9999999999999999, "Mobile Number must be less than 16")
      .notRequired()
      .typeError("Please enter a valid number"),
    mobileNumber: yup
      .number()
      .min(999999, "Mobile Number must be greater than 6")
      .max(9999999999999999, "Mobile Number must be less than 16")
      .notRequired()
      .typeError("Please enter a valid number"),
    phoneCode: yup.string().required().default(`+44`),
    mobileCode: yup.string().required().default(`+44`),
  })
  .required();

const UserForm = (props) => {
  const history = useHistory();

  const userManagementContext = useContext(UserManagementContext);
  const { currentViewUser } = userManagementContext;

  const [isEditMode, updateEditMode] = useBoolean(false);

  const userFormRef = useForm({
    resolver: yupResolver(props.userId ? updateSchema : createSchema),
  });

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = userFormRef;

  useEffect(() => {
    if (props.userId) {
      userManagementContext.loadUser(props.userId);
    }
  }, [props.userId]);

  useEffect(() => {
    if (currentViewUser) {
      updateEditMode.on();
      const { attachment, deletedAt, ...userData } = currentViewUser;
      reset({
        ...userData,
        phoneCode: userData.phoneNumber && userData.phoneNumber.split(" ")[0],
        phoneNumber: userData.phoneNumber && userData.phoneNumber.split(" ")[1],
        mobileCode: userData.mobileNumber && userData.mobileNumber.split(" ")[0],
        mobileNumber: userData.mobileNumber && userData.mobileNumber.split(" ")[1],
      });
    }
  }, [currentViewUser]);

  const handleFormSubmit = (formData) => {
    if (isEditMode) {
      userManagementContext
        .updateUser(currentViewUser.id, {
          ...formData,
          phoneNumber: `${formData.phoneCode} ${formData.phoneNumber}`,
          mobileNumber: `${formData.mobileCode} ${formData.mobileNumber}`,
        })
        .then((res) => {
          history.replace(CLIENT_USER_PROFILE_PAGE);
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    } else {
      userManagementContext
        .createUser({
          ...formData,
          phoneNumber: `${formData.phoneCode} ${formData.phoneNumber}`,
          mobileNumber: `${formData.mobileCode} ${formData.mobileNumber}`,
        })
        .then((res) => {
          history.replace(CLIENT_USER_PROFILE_PAGE);
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Create User
      </Button>
    );
  };

  const renderEditButton = () => {
    return isDirty ? (
      <Button color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Save Information
      </Button>
    ) : (
      <Button size="lg" color="outline-primary" onClick={() => history.replace(CLIENT_USER_PROFILE_PAGE)}>
        Cancel
      </Button>
    );
  };

  const cancel = () => {
    return (
      <Button color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Save Information
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      if (isDirty) {
        return renderEditButton();
      } else {
        return cancel();
      }
    } else {
      return renderCreateButton();
    }
  };

  return (
    <Block size="lg">
      <PreviewCard>
        <div className="card-head">
          <h5 className="card-title">User Information</h5>
        </div>
        <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
          <Row className="g-4">
            <Col lg="6">
              <FormGroup>
                <Label className="form-label" htmlFor="fv-full-name">
                  First Name
                </Label>
                <div className="form-control-wrap">
                  <input {...register("firstName")} type="text" name="firstName" className="form-control" />
                  {errors.firstName && <span className="invalid">{errors.firstName?.message}</span>}
                </div>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label className="form-label" htmlFor="fv-full-name">
                  Last Name
                </Label>
                <div className="form-control-wrap">
                  <input {...register("lastName")} type="text" name="lastName" className="form-control" />
                  {errors.lastName && <span className="invalid">This field is required</span>}
                </div>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label className="form-label" htmlFor="fv-full-name">
                  Email address
                </Label>
                <div className="form-control-wrap">
                  <input
                    {...register("email")}
                    type="text"
                    name="email"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {errors.email && <span className="invalid">{errors.email?.message}</span>}
                </div>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="form-group">
                <label className="form-label">Phone Number</label>
                <div className="form-control-wrap d-flex">
                  <Col xs="4" md="2" lg="3" className="p-0">
                    <Controller
                      control={control}
                      name="phoneCode"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = phoneCode;
                        const selectedValue = options.find((e) => e.value === field.value);

                        return (
                          <RSelect
                            defaultValue={options[0]}
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => setValue("phoneCode", o.value)}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col xs="8" md="10" lg="9" className="p-0">
                    <input {...register("phoneNumber")} type="text" name="phoneNumber" className="form-control" />
                    {errors.phoneNumber && <span className="invalid">{errors.phoneNumber?.message}</span>}
                  </Col>
                </div>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="form-group">
                <label className="form-label">Mobile Number</label>
                <div className="form-control-wrap d-flex">
                  <Col xs="4" md="2" lg="3" className="p-0">
                    <Controller
                      control={control}
                      name="mobileCode"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = phoneCode;
                        const selectedValue = options.find((e) => e.value === field.value);

                        return (
                          <RSelect
                            defaultValue={options[0]}
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => setValue("mobileCode", o.value)}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col xs="8" md="10" lg="9" className="p-0">
                    <input type="text" name="mobileNumber" className="form-control" {...register("mobileNumber")} />
                    {errors.mobileNumber && <span className="invalid">{errors.mobileNumber?.message}</span>}
                  </Col>
                </div>
              </FormGroup>
            </Col>
            <Col xl="12" className="d-flex justify-content-end">
              {renderSaveButton()}
            </Col>
          </Row>
        </Form>
      </PreviewCard>
    </Block>
  );
};

export default UserForm;
