/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useState } from "react";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../../components/Component";
import download from "js-file-download";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { exportString } from "../StringRepository";

const STRING_FIELDS = [
  {
    label: "Identifier",
    value: "identifier",
  },
  {
    label: "Plant Name",
    value: "plant.name",
  },
  {
    label: "Added At",
    value: "a",
  },
  {
    label: "Investigated At",
    value: "i",
  },
  {
    label: "Repaired At",
    value: "r",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Failure Reason",
    value: "failureReason.name",
  },
  {
    label: "Monitoring Comments",
    value: "monitoringComments",
  },
  {
    label: "Works Comments",
    value: "workComments",
  },
  {
    label: "No. of Strings fixed per duct",
    value: "noOfStringsPerDuct",
  },
];

const ExportForm = (props) => {
  const { taskCurrentStatus, filterParams, plantId } = props;
  const [value, setValue] = useState(
    STRING_FIELDS.map((plant) => {
      return plant.value;
    })
  );
  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async () => {
    try {
      setExportLoading(true);
      const response = await exportString({
        ...filterParams,
        selectedPlant: plantId ? [plantId] : filterParams?.selectedPlant,
        selectedStatus: filterParams?.selectedStatus.length === 0 ? taskCurrentStatus : filterParams?.selectedStatus,
        category: props.category,
        headerData: value,
      });
      download(response, `string.csv`);
      successToast({ description: "Successfully Exported" });
      props.setIsExportModalOpen(true);
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="export--correctiveForm"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Task Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0">
                {STRING_FIELDS &&
                  STRING_FIELDS.map((string, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${string} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0">
                              <input
                                key={`${string}-${key}`}
                                type="checkbox"
                                checked={value.includes(string.value)}
                                id={`${string}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([string.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === string.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${string}-${key}`} check>
                                {string.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;
