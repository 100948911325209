/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewCorrectiveTask from "../components/CorrectiveTaskView";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import ViewPreventativeTask from "../components/PreventativeTaskView";

const CorrectiveTaskViewContainer = (props) => {
  const { taskId, setIsOpen } = props;

  const pvPlantContext = useContext(PvPlantContext);
  const { loadPvPlantTask } = pvPlantContext;

  useEffect(() => {
    if (taskId) loadPvPlantTask(taskId);
  }, [taskId]);

  return (
    <React.Fragment>
      <ViewPreventativeTask setIsOpen={setIsOpen} />
    </React.Fragment>
  );
};

export default withPvPlantProvider(CorrectiveTaskViewContainer);
