import { format } from "date-fns";
import React, { useContext } from "react";
import { Col, FormGroup, Spinner } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle } from "../../../components/Component";
import Attachments from "../../../components/attachments/Attachment";
import { TASK } from "../../equipment_spares/constants";
import { TicketManagementContext } from "../TicketManagementProvider";
import { MONTH } from "../strings/components/constants";

const ViewTicket = ({ sm, updateSm, isPreventativeMaintenance }) => {
  const ticketManagementContext = useContext(TicketManagementContext);
  const { currentViewTicket } = ticketManagementContext;

  if (!currentViewTicket) return <Spinner />;
  return (
    <div className="overflow-auto h-max-450px">
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md">
          <div id="top" className="d-md-flex justify-content-md-between">
            <BlockHeadContent>
              <BlockTitle tag="h4"> {currentViewTicket.title}</BlockTitle>
              <BlockTitle tag="h6">{currentViewTicket.identifier} </BlockTitle>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <div className="nk-data data-list">
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Ticket Name</span>
              <span className="data-value">{currentViewTicket.title || "-"} </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Status</span>
              <span className="data-value">{currentViewTicket.status || "-"} </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          {isPreventativeMaintenance && (
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Code</span>
                <span className="data-value">{currentViewTicket.code || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
          )}
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Assigned Engineer</span>
              <span className="data-value">
                {currentViewTicket?.assignedEngineer
                  ? currentViewTicket?.assignedEngineer
                      ?.map((item, key) => {
                        return `${item.firstName} ${item.lastName}`;
                      })
                      .join(", ")
                  : "-"}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Start Date</span>
              <span className="data-value">
                {" "}
                {currentViewTicket?.startedAt
                  ? format(new Date(currentViewTicket?.startedAt || null), "dd MMM yyyy")
                  : "-"}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Resolved Date</span>
              <span className="data-value">
                {" "}
                {currentViewTicket?.resolvedAt
                  ? format(new Date(currentViewTicket?.resolvedAt || null), "dd/MM/yyyy")
                  : "-"}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          {/*  <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Priority</span>
                <span className="profile-ud-value"> {currentViewTicket.priority || "NA"}</span> 
              </div>
            </div>*/}
          {/*   <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Error/Issue</span>
                <span className="profile-ud-value">{"NA"}</span> 
              </div>
            </div>*/}
          {/*  <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Team Leader</span>
                <span className="profile-ud-value">
                  {currentViewTicket?.fieldEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ")}
                </span> 
              </div>
            </div>*/}
          {/*  <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Description</span>
                <span className="profile-ud-value">{currentViewTicket.description || "NA"}</span> 
              </div>
            </div>*/}
          {/*   <div className="profile-ud-item">
              <div className="profile-ud wider">
               <span className="profile-ud-label">Comments</span>
                <span className="profile-ud-value">{currentViewTicket?.comments?.description || "NA"}</span> 
              </div>
            </div>*/}
          {/*  <div className="profile-ud-item">
              <div className="profile-ud wider">
                 <span className="profile-ud-label">Labour Hours</span>
                <span className="profile-ud-value">{currentViewTicket.labourHours || "NA"}</span> 
              </div>
            </div>*/}
          {/* <div className="profile-ud-item">
              <div className="profile-ud wider">
                 <span className="profile-ud-label">Attachments</span>
                <span className="profile-ud-value">{currentViewTicket.labourHours || "NA"}</span>
              </div>
            </div>
          </div> */}
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Comments</span>
              <span className="data-value text-editor" dangerouslySetInnerHTML={{ __html: currentViewTicket?.comment || "-" }} />
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Description</span>
              <span className="data-value text-editor" dangerouslySetInnerHTML={{__html: currentViewTicket?.description || "-"}}/>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          {isPreventativeMaintenance && (
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Month</span>
                <span className="data-value">{currentViewTicket.months ? MONTH[currentViewTicket.months] : "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
          )}
          {isPreventativeMaintenance && (
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Year</span>
                <span className="data-value">{currentViewTicket.year || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
          )}
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Plant Name</span>
              <span className="data-value">{currentViewTicket.plant.name || "-"} </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <Col md="12" className="p-1">
            <FormGroup className="form-group">
              <div className="form-control-wrap">
                <Attachments
                  module={TASK}
                  id={currentViewTicket.id}
                  attachments={currentViewTicket.attachment}
                  subModule={"view"}
                />
              </div>
            </FormGroup>
          </Col>
          <div className="nk-data data-list">
            <div>
              <small class="text-muted">
                CreatedAt: {format(new Date(currentViewTicket?.created_at || null), "dd MMM yyyy HH:mm")}{" "}
              </small>
            </div>
            <div>
              <small class="text-muted">
                UpdatedAt: {format(new Date(currentViewTicket?.updated_at || null), "dd MMM yyyy HH:mm")}{" "}
              </small>
            </div>
          </div>
        </div>
      </Block>
    </div>
  );
};
export default ViewTicket;
