/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { TicketManagementContext, withTicketManagementProvider } from "../TicketManagementProvider";
import TicketList from "../components/TicketList";

const TicketManagementContainer = (props) => {
  const { loadTicketList } = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions } = useContext(TicketManagementContext);

  useEffect(() => {
    loadTicketList();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);
  return (
    <React.Fragment>
      <Head title="Pv-Plant" />
      <Content>
        <TicketList />
      </Content>
    </React.Fragment>
  );
};

export default withTicketManagementProvider(TicketManagementContainer);
