import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";
import { Icon } from "../Component";
import { format } from "date-fns";
import { getFileSizeText } from "../../utils/Utils";

const Card = (props) => {
  const { src, type, file, handleDelete, deleteLoading, fileId, subModule } = props;
  const FILE_FORMAT = {
    PDF: "pdf",
    DOC: "doc",
    XLS: "xls",
  };
  let icon = "";
  if (type.includes(FILE_FORMAT.PDF)) {
    icon = `file-${FILE_FORMAT.PDF}`;
  } else if (type.includes(FILE_FORMAT.DOC)) {
    icon = `file-${FILE_FORMAT.DOC}`;
  } else if (type.includes(FILE_FORMAT.XLS)) {
    icon = `file-${FILE_FORMAT.XLS}`;
  } else {
    icon = `file-fill`;
  }
  return (
    <>
      <div className="team border border-light p-3 rounded-lg">
        <div className="team-status  text-white"></div>
        <div className="team-options ">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu right>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <span>Created Time:{format(new Date(file?.createdAt || new Date()), "dd/MM/yyyy HH:mm")}</span>
                  </DropdownItem>
                </li>
                <li className="divider"></li>
                {handleDelete && subModule !== "view" && (
                  <li>
                    <DropdownItem
                      disabled={deleteLoading}
                      tag="a"
                      href="#suspend"
                      onClick={(ev) => {
                        handleDelete(file);
                      }}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  </li>
                )}
                <li>
                  <DropdownItem
                    tag="a"
                    href="#suspend"
                    onClick={(ev) => {
                      window.open(src);
                    }}
                  >
                    <Icon name="external"></Icon>
                    <span>Open</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="user-card user-card-s2 h-150px overflow-hidden justify-content-center">
          {deleteLoading && fileId === file.id && <Spinner />}
          {(deleteLoading && fileId === file.id && <></>) ||
            (type.includes("image") && <img src={src} alt="preview" />) || (
              <Icon style={{ fontSize: "97px" }} name={icon}></Icon>
            )}
        </div>
        <div className="user-info ">
          <span className="sub-text text-ellipsis" data-toggle="tooltip" data-placement="top" title={file?.name}>
            {" "}
            {file?.name}
          </span>
          <span className="sub-text text-ellipsis"> {getFileSizeText(file.size)}</span>
          <span
            className="sub-text text-ellipsis"
            data-toggle="tooltip"
            data-placement="top"
            title={file?.userName ? file.userName : `${file.user?.firstName} ${file.user?.lastName}`}
          >
            Uploaded By : {file?.userName ? file.userName : `${file.user?.firstName} ${file.user?.lastName}`}
          </span>
        </div>
      </div>
    </>
  );
};
export default Card;
