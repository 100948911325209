import apiService from "../../services/ApiService";
import {
  CLIENT_PVPLANT_LIST,
  CLIENT_PLANT_VIEW_API,
  CLIENT_PLANT_TASK_LIST,
  CLIENT_PLANT_TASK_VIEW,
  EXPORT_CLIENT_PLANT_TASK_API,
  CLIENT_TASK_FILTER_OPTIONS,
  CLIENT_PVPLANT_FILTER_OPTIONS,
  CLIENT_PLANT_VIEW_TOTAL_TASKS_API,
  EXPORT_CLIENT_PV_PLANT,
} from "../../constants/endpoints";

export const getAllPlants = async (filterParams) => {
  const response = await apiService.get(CLIENT_PVPLANT_LIST, { queryParams: filterParams });

  return response;
};

export const getPvPlantAPI = async (plantId) => {
  const response = await apiService.get(CLIENT_PLANT_VIEW_API, {
    pathParams: { plantId },
  });
  return response;
};
export const getPvPlantTasksAPI = async (filterParams, plantId) => {
  const response = await apiService.get(CLIENT_PLANT_TASK_LIST, {
    queryParams: filterParams,
    pathParams: { plantId },
  });
  return response;
};

export const getPvplantFilterOptions = async () => {
  const response = await apiService.get(CLIENT_PVPLANT_FILTER_OPTIONS, {});
  return response;
};

export const getPvPlantTask = async (taskId) => {
  const response = await apiService.get(CLIENT_PLANT_TASK_VIEW, {
    pathParams: { taskId },
  });
  return response;
};

export const exportClientPlantTask = async (filterParams) => {
  const response = await apiService.get(EXPORT_CLIENT_PLANT_TASK_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
export const exportClientPlant = async (filterParams) => {
  const response = await apiService.get(EXPORT_CLIENT_PV_PLANT, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
export const getTaskListFilterOptions = async (filterParams) => {
  const response = await apiService.get(CLIENT_TASK_FILTER_OPTIONS);
  return response;
};
export const getPvPlantTotalTasksAPI = async (plantId) => {
  const response = await apiService.get(CLIENT_PLANT_VIEW_TOTAL_TASKS_API, {
    pathParams: { plantId },
  });
  return response;
};
