import { useContext } from "react";
import { FormGroup } from "reactstrap";
import Attachments from "../../../../components/attachments/Attachment";
import { EquipmentAndSpareContext } from "../../EquipmentSparesProvider";

const AttachmentComponent = () => {
  const { currentClientEquipment } = useContext(EquipmentAndSpareContext);

  return (
    <>
      {currentClientEquipment?.id ? (
        <div className="ml-4">
          <FormGroup className="form-group">
            <div className="form-control-wrap">
              <Attachments
                module={"STOCK"}
                id={currentClientEquipment?.id}
                attachments={currentClientEquipment.attachment}
                subModule={""}
              />
            </div>
          </FormGroup>
        </div>
      ) : null}
    </>
  );
};
export default AttachmentComponent;
