import apiService from "../services/ApiService";
import { GET_FILE_API } from "../constants/endpoints";

export const getFile = async (key) => {
  const response = await apiService.get(GET_FILE_API, {
    pathParams: {
      key,
    },
  });
  return response;
};
