import React, { useContext } from "react";
import { Col, FormGroup, Spinner } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent } from "../../../components/Component";
import { PvPlantContext } from "../PvPlantProvider";
import { format } from "date-fns";
import Attachments from "../../../components/attachments/Attachment";
import { TASK } from "../../equipment_spares/constants";
import { MONTH } from "../../ticket_management/strings/components/constants";

const ViewPreventativeTask = ({ sm, updateSm }) => {
  const pvPlantContext = useContext(PvPlantContext);
  const { currentViewTask } = pvPlantContext;

  if (!currentViewTask) return <Spinner />;
  return (
    <div className="overflow-auto h-max-450px">
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md" className="d-flex justify-content-end">
          <BlockBetween>
            <BlockHeadContent className="align-self-end"></BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Ticket Name</span>
                <span className="data-value">{currentViewTask.title || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Plant Name</span>
                <span className="data-value">{currentViewTask?.plant.name || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Code</span>
                <span className="data-value">{currentViewTask?.code || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Start Date</span>
                <span className="data-value">
                  {currentViewTask?.startedAt ? format(new Date(currentViewTask?.startedAt), "dd MMM yyyy") : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Resolved Date</span>
                <span className="data-value">
                  {" "}
                  {currentViewTask?.resolvedAt
                    ? format(new Date(currentViewTask?.resolvedAt || null), "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Assigned Engineer</span>
                <span className="data-value">
                  {currentViewTask?.assignedEngineer
                    ? currentViewTask?.assignedEngineer
                        ?.map((item, key) => {
                          return `${item.firstName} ${item.lastName}`;
                        })
                        .join(", ")
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Month</span>
                <span className="data-value">{currentViewTask.months ? MONTH[currentViewTask.months] : "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Year</span>
                <span className="data-value">{currentViewTask.year || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Comments</span>
                <span className="data-value text-editor" dangerouslySetInnerHTML={{__html: currentViewTask?.comment || "-"}}/>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Description</span>
                <span className="data-value text-editor" dangerouslySetInnerHTML={{__html: currentViewTask?.description || "-"}}/>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            {/* <div className="data-item">
            <div className="data-col">
              <span className="data-label">Labour Hours</span>
              <span className="data-value"> {currentViewTask.labourHours || "-"}</span>
            </div>
            <div className="data-col data-col-end"></div>
          </div> */}
            <Col md="12" className="p-1">
              <FormGroup className="form-group">
                <div className="form-control-wrap">
                  <Attachments
                    module={TASK}
                    id={currentViewTask.id}
                    attachments={currentViewTask.attachment}
                    subModule={"view"}
                  />
                </div>
              </FormGroup>
            </Col>
          </div>
          <div className="nk-data data-list">
            <div>
              <small class="text-muted">
                CreatedAt: {format(new Date(currentViewTask?.created_at || null), "dd MMM yyyy HH:mm")}{" "}
              </small>
            </div>
            <div>
              <small class="text-muted">
                UpdatedAt: {format(new Date(currentViewTask?.updated_at || null), "dd MMM yyyy HH:mm")}{" "}
              </small>
            </div>
          </div>
        </Block>
      </Block>
    </div>
  );
};
export default ViewPreventativeTask;
