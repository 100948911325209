/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
// import PvPlantForm from "../components/PvPlantForm";
import PvPlantList from "../components/PvPlantList";
import { withPvPlantProvider, PvPlantContext } from "../PvPlantProvider";

const PvPlantFormContainer = (props) => {
  const params = useParams();
  const { plantId } = params;

  const { loadAllPlants, loadPvPlant, loadFilterOptions, filterParams, loadPvPlantFilterOptions, pagination } =
    useContext(PvPlantContext);
  useEffect(() => {
    loadFilterOptions();
    loadPvPlantFilterOptions();
  }, []);
  useEffect(() => {
    if (plantId) {
      loadPvPlant(plantId);
    }
  }, [plantId]);

  useEffect(() => {
    loadAllPlants();
  }, []);
  const title = "PV-Plant";

  useEffect(() => {
    loadAllPlants();
  }, [filterParams, pagination]);

  return (
    <React.Fragment>
      <Head title={title} />
      <Content>
        <PvPlantList />
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantProvider(PvPlantFormContainer);
