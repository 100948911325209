import Swal from "sweetalert2";

export const confirmationSweetAlert = props => {
    const { title, text, icon, confirmButtonText, handleConfirmation, id } = props
    Swal.fire({
        title: title || "Are you sure?",
        text: text || "You won't be able to revert this!",
        icon: icon || "warning",
        showCancelButton: true,
        confirmButtonText: confirmButtonText || "Yes, delete it!",
    }).then((result) => {
        if (result.isConfirmed) {
            handleConfirmation(id)
        }
    })
}

export const handleSuccessSweetAlert = () => {
    Swal.fire({
      icon: "success",
      title: " Email sent successfully",
      text: "Please check your email instruction has been sent to reset password",
      focusConfirm: false,
    });
  };

  export const handleWarningSweetAlert = (errorMessage) => {
    Swal.fire({
        icon: "warning",
        title: "Reset Password",
        text: `${errorMessage}`,
        focusConfirm: false,
      });
    };