import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
// import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
// import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { RedirectAs404 } from "../utils/Utils";

// import Sales from "../pages/Sales";
// import Crypto from "../pages/Crypto";
// import Analytics from "../pages/Analytics";
// import Invest from "../pages/Invest";

// import Overview from "../pages/panel/invest/Overview";
// import Plan from "../pages/panel/invest/Plan";
// import Profile from "../pages/panel/invest/Profile";
// import Investment from "../pages/panel/invest/Investment";
// import InvestmentProcess from "../pages/panel/invest/pages/InvesmentProcess";
// import InvestmentDetails from "../pages/panel/invest/pages/InvestmentDetails";
// import Welcome from "../pages/panel/invest/pages/Welcome";
// import KycApplications from "../pages/panel/invest/pages/KycApplications";
// import KycForms from "../pages/panel/invest/pages/KycForms";

// import Component from "../pages/components/Index";
// import Accordian from "../pages/components/Accordions";
// import Alerts from "../pages/components/Alerts";
// import Badges from "../pages/components/Badges";
// import Breadcrumbs from "../pages/components/Breadcrumbs";
// import ButtonGroup from "../pages/components/ButtonGroup";
// import Buttons from "../pages/components/Buttons";
// import Cards from "../pages/components/Cards";
// import Carousel from "../pages/components/Carousel";
// import Dropdowns from "../pages/components/Dropdowns";
// import FormElements from "../pages/components/forms/FormElements";
// import FormLayouts from "../pages/components/forms/FormLayouts";
// import FormValidation from "../pages/components/forms/FormValidation";
// import Modals from "../pages/components/Modals";
// import Pagination from "../pages/components/Pagination";
// import Popovers from "../pages/components/Popovers";
// import Progress from "../pages/components/Progress";
// import Spinner from "../pages/components/Spinner";
// import Tabs from "../pages/components/Tabs";
// import Toast from "../pages/components/Toast";
// import Tooltips from "../pages/components/Tooltips";
// import UtilBorder from "../pages/components/UtilBorder";
// import UtilColors from "../pages/components/UtilColors";
// import UtilDisplay from "../pages/components/UtilDisplay";
// import UtilEmbeded from "../pages/components/UtilEmbeded";
// import UtilFlex from "../pages/components/UtilFlex";
// import UtilOthers from "../pages/components/UtilOthers";
// import UtilSizing from "../pages/components/UtilSizing";
// import UtilSpacing from "../pages/components/UtilSpacing";
// import UtilText from "../pages/components/UtilText";

// import Blank from "../pages/others/Blank";
// import Faq from "../pages/others/Faq";
// import Regularv1 from "../pages/others/Regular-1";
// import Regularv2 from "../pages/others/Regular-2";
// import Terms from "../pages/others/Terms";
// import BasicTable from "../pages/components/table/BasicTable";
// import SpecialTablePage from "../pages/components/table/SpecialTable";
// import ChartPage from "../pages/components/charts/Charts";
// import EmailTemplate from "../pages/components/email-template/Email";
// import NioIconPage from "../pages/components/crafted-icons/NioIcon";
// import SVGIconPage from "../pages/components/crafted-icons/SvgIcons";

// import ProjectCardPage from "../pages/pre-built/projects/ProjectCard";
// import ProjectListPage from "../pages/pre-built/projects/ProjectList";
// import UserListRegularPage from "../pages/pre-built/user-manage/UserListRegular";
// import UserContactCardPage from "../pages/pre-built/user-manage/UserContactCard";
// import UserDetailsPage from "../pages/pre-built/user-manage/UserDetailsRegular";
// import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
// import UserProfileLayout from "../pages/pre-built/user-manage/UserProfileLayout";
// import KycListRegular from "../pages/pre-built/kyc-list-regular/KycListRegular";
// import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
// import TransListBasic from "../pages/pre-built/trans-list/TransListBasic";
// import TransListCrypto from "../pages/pre-built/trans-list/TransListCrypto";
// import ProductCard from "../pages/pre-built/products/ProductCard";
// import ProductList from "../pages/pre-built/products/ProductList";
// import ProductDetails from "../pages/pre-built/products/ProductDetails";
// import InvoiceList from "../pages/pre-built/invoice/InvoiceList";
// import InvoiceDetails from "../pages/pre-built/invoice/InvoiceDetails";
// import PricingTable from "../pages/pre-built/pricing-table/PricingTable";
// import GalleryPreview from "../pages/pre-built/gallery/GalleryCardPreview";

// import AppMessages from "../pages/app/messages/Messages";
// import Chat from "../pages/app/chat/ChatContainer";
// import Calender from "../pages/app/calender/Calender";
// import DateTimePicker from "../pages/components/forms/DateTimePicker";
// import ProfileActivity from "../pages/panel/invest/ProfileActivity";

import {
  HOME_PAGE,
  CLIENT_PVPLANT_PAGE,
  CLIENT_TICKET_LIST,
  CLIENT_USER_PROFILE_PAGE,
  CLIENT_USER_PROFILE_SETTING,
  CLIENT_USER_PROFILE_UPDATE,
  CLIENT_SPARE_PARTS,
  EQUIPMENT_SPARE_VIEW,
  CLIENT_PVPLANT_VIEW,
  CORRECTIVE_MAINTENANCE_PAGE,
  PREVENTATIVE_TASKS_PAGE,
  REMEDIAL_MAINTENANCE_PAGE,
  STRING_MAINTENANCE,
  GLOBAL_SEARCH_MANAGEMENT_PAGE,
  MAINTENANCE_TICKET_VIEW_PAGE
} from "../constants/routes";
import HomeContainer from "../pages/home/HomeContainer";
import PvPlantFormContainer from "../pages/plant_management/containers/PvPlantFormContainer";
import TicketContainer from "../pages/ticket_management/containers/TicketContainer";
import UserProfileLayout from "../pages/user_management/user_profile/UserProfileLayout";
import UserProfileSettingPage from "../pages/user_management/user_profile/UserProfileSetting";
import UserManagementProfileUpdateConatiner from "../pages/user_management/container/UserManagementProfileUpdateConatiner";
import PvPlantViewContainer from "../pages/plant_management/containers/PvPlantViewContainer";
import CorrectiveTable from "../pages/ticket_management/maintenance _tables/CorrectiveTable";
import PreventiveTable from "../pages/ticket_management/maintenance _tables/PreventiveTable";
import RemedialTable from "../pages/ticket_management/maintenance _tables/RemedialTable";
import StringListContainer from "../pages/ticket_management/strings/containers/StringListContainer";
import EquipmentAndSpareViewContainer from "../pages/equipment_spares/containers/EquipmentAndSpareViewContainer";
import EquipmentAndSpareContainer from "../pages/equipment_spares/containers/EquipmentAndSpareContainer"
import GlobalSearchContainer from "../pages/global_search/GlobalSearchContainer"
import ViewTicketContainer from "../pages/ticket_management/containers/ViewTicketContainer";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/* Dashboards
        <Route exact path={`${process.env.PUBLIC_URL}/crypto`} component={Crypto}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/analytics`} component={Analytics}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sales`} component={Sales}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/_blank`} component={Blank}></Route>

        {/*Panel
        <Route exact path={`${process.env.PUBLIC_URL}/invest`} component={Overview}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/index`} component={Overview}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/schemes`} component={Plan}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/profile`} component={Profile}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/profile-notify`} component={Profile}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/profile-setting`} component={Profile}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/profile-connected`} component={Profile}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/profile-activity`} component={ProfileActivity}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/invest`} component={Investment}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/invest-form/:id`} component={InvestmentProcess}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/scheme-details/:id`} component={InvestmentDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/welcome`} component={Welcome}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/kyc-application`} component={KycApplications}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest/kyc-form`} component={KycForms}></Route>

        {/*Pre-built Pages
        <Route exact path={`${process.env.PUBLIC_URL}/project-card`} component={ProjectCardPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/project-list`} component={ProjectListPage}></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-regular`}
          render={() => (
            <UserContextProvider>
              <UserListRegularPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-compact`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-details-regular/:id`}
          render={(props) => (
            <UserContextProvider>
              <UserDetailsPage {...props} />
            </UserContextProvider>
          )}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-regular/`} component={UserProfileLayout}></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-notification/`}
          component={UserProfileLayout}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-activity/`} component={UserProfileLayout}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-setting/`} component={UserProfileLayout}></Route>
        <Route //Context api added
          exact
          path={`${process.env.PUBLIC_URL}/user-contact-card`}
          render={() => (
            <UserContextProvider>
              <UserContactCardPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/kyc-list-regular`} component={KycListRegular}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/kyc-details-regular/:id`} component={KycDetailsRegular}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/transaction-basic`} component={TransListBasic}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/transaction-crypto`} component={TransListCrypto}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/product-list`} component={ProductList}></Route>

        <Route // context api added
          exact
          path={`${process.env.PUBLIC_URL}/product-card`}
          render={(props) => (
            <ProductContextProvider>
              <ProductCard />
            </ProductContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/product-details/:id`}
          render={(props) => (
            <ProductContextProvider>
              <ProductDetails {...props} />
            </ProductContextProvider>
          )}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invoice-list`} component={InvoiceList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invoice-details/:id`} component={InvoiceDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/image-gallery`} component={GalleryPreview}></Route>

        {/*Demo Pages
        <Route exact path={`${process.env.PUBLIC_URL}/pages/terms-policy`} component={Terms}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/regular-v1`} component={Regularv1}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/regular-v2`} component={Regularv2}></Route>

        {/*Application
        <Route exact path={`${process.env.PUBLIC_URL}/app-messages`} component={AppMessages}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/app-chat`} component={Chat}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/app-calender`} component={Calender}></Route>

        {/*Components
        <Route exact path={`${process.env.PUBLIC_URL}/components`} component={Component}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/accordions`} component={Accordian}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/alerts`} component={Alerts}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/badges`} component={Badges}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/breadcrumbs`} component={Breadcrumbs}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/button-group`} component={ButtonGroup}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/buttons`} component={Buttons}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/cards`} component={Cards}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/carousel`} component={Carousel}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/dropdowns`} component={Dropdowns}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/form-elements`} component={FormElements}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/form-layouts`} component={FormLayouts}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/form-validation`} component={FormValidation}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/datetime-picker`} component={DateTimePicker}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/modals`} component={Modals}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/pagination`} component={Pagination}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/popovers`} component={Popovers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/progress`} component={Progress}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/spinner`} component={Spinner}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/tabs`} component={Tabs}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/toast`} component={Toast}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/tooltips`} component={Tooltips}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-border`} component={UtilBorder}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-colors`} component={UtilColors}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-display`} component={UtilDisplay}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-embeded`} component={UtilEmbeded}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-flex`} component={UtilFlex}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-others`} component={UtilOthers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-sizing`} component={UtilSizing}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-spacing`} component={UtilSpacing}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-text`} component={UtilText}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/table-basic`} component={BasicTable}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/table-special`} component={SpecialTablePage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/charts/chartjs`} component={ChartPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/email-template`} component={EmailTemplate}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/nioicon`} component={NioIconPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/svg-icons`} component={SVGIconPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Invest}></Route>*/}

        <Route exact path={HOME_PAGE} component={HomeContainer}></Route>
        <Route exact path={CLIENT_USER_PROFILE_PAGE} component={UserProfileLayout}></Route>

        <Route exact path={CLIENT_USER_PROFILE_SETTING} component={UserProfileSettingPage}></Route>

        <Route exact path={CLIENT_USER_PROFILE_UPDATE} component={UserManagementProfileUpdateConatiner}></Route>

        {/* PV-PLANT MANAGEMENT */}
        <Route exact path={CLIENT_PVPLANT_PAGE} component={PvPlantFormContainer} />
        <Route exact path={CLIENT_PVPLANT_VIEW} component={PvPlantViewContainer} />

        {/*TICKET MANAGEMENT */}
        {/* <Route exact path={CLIENT_TICKET_LIST} component={TicketContainer} /> */}
        <Route exact path={CLIENT_TICKET_LIST} component={TicketContainer} />
        <Route exact path={CORRECTIVE_MAINTENANCE_PAGE} component={CorrectiveTable} />
        <Route exact path={PREVENTATIVE_TASKS_PAGE} component={PreventiveTable} />
        <Route exact path={REMEDIAL_MAINTENANCE_PAGE} component={RemedialTable} />
        <Route exact path={STRING_MAINTENANCE} component={StringListContainer} />
        <Route exact path={MAINTENANCE_TICKET_VIEW_PAGE} component={ViewTicketContainer}></Route>

        {/* SPAREPARTS MANAGEMENT */}
        <Route exact path={EQUIPMENT_SPARE_VIEW} component={EquipmentAndSpareViewContainer} />
        <Route exact path={CLIENT_SPARE_PARTS} component={EquipmentAndSpareContainer} />

        {/* GLOBAL SEARCH */}
        <Route exact path={GLOBAL_SEARCH_MANAGEMENT_PAGE} component={GlobalSearchContainer} />

        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
