import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardTitle } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  PreviewAltCard,
  PreviewCard,
  Row,
  UserAvatar,
} from "../../components/Component";
import {
  CLIENT_PVPLANT_VIEW,
  CORRECTIVE_MAINTENANCE_PAGE,
  PREVENTATIVE_TASKS_PAGE,
  REMEDIAL_MAINTENANCE_PAGE,
  STRING_MAINTENANCE,
} from "../../constants/routes";
import Head from "../../layout/head/Head";
import { findUpper } from "../../utils/Utils";
import { HomeContext } from "./HomeProvider";
import Skeleton from "react-loading-skeleton";

const HomePage = () => {
  const homeContext = useContext(HomeContext);
  const { plantsList, totalTask, taskLoading, plantLoading } = homeContext;
  const history = useHistory();
  const plantItems = plantsList || [];

  const PLANTSTATUSES = {
    ACTIVE: "Active",
    IN_ACTIVE: "InActive",
    SUSPENDED: "Suspended",
  };
  const plantStatusObj = {
    IN_ACTIVE: "danger",
    ACTIVE: "success",
    SUSPENDED: "warning",
  };

  const header = (props) => {
    switch (props) {
      case "correctiveTaskTotal":
        return "Corrective";
      case "preventativeTaskTotal":
        return "Preventative (Current month)";
      case "remedialTaskTotal":
        return "Remedial";
      case "stringTotal":
        return "String";
      default:
        return "n/a";
    }
  };

  const switchRoutes = (props) => {
    switch (props) {
      case "correctiveTaskTotal":
        return CORRECTIVE_MAINTENANCE_PAGE;
      case "preventativeTaskTotal":
        return PREVENTATIVE_TASKS_PAGE;
      case "remedialTaskTotal":
        return REMEDIAL_MAINTENANCE_PAGE;
      case "stringTotal":
        return STRING_MAINTENANCE;
      default:
        return "n/a";
    }
  };
  const plantTaskDataKeys = Object.entries(totalTask);

  const titleData = (props) => {
    switch (props) {
      case "OPEN":
        return <div style={{ color: "red" }}>New</div>;
      case "IN_PROGRESS":
        return <div style={{ color: "green" }}>In progress</div>;
      case "READY_FOR_APPROVAL":
        return <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>Ready for approval</div>;
      case "COMPLETED":
        return <div style={{ color: "blue" }}>Closed</div>;
      default:
        return "n/a";
    }
  };

  const subTitleData = (data, props) => {
    switch (props) {
      case "OPEN":
        return <div style={{ color: "red" }}>{`${data.weeklyCount} vs last week`}</div>;
      case "IN_PROGRESS":
        return <div style={{ color: "green" }}>{`${data.weeklyCount} vs last week`}</div>;
      case "READY_FOR_APPROVAL":
        return (
          <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>
            {`${data.weeklyCount} vs last week`}
          </div>
        );
      case "COMPLETED":
        return <div style={{ color: "blue" }}>{`${data.weeklyCount} vs last week`}</div>;
      case "VALIDATE":
        return (
          <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>
            {`${data.weeklyCount} vs last week`}
          </div>
        );
      case "CLOSED":
        return <div style={{ color: "blue" }}>{`${data.weeklyCount} vs last week`}</div>;
      default:
        return "n/a";
    }
  };
  return (
    <Block className={"ml-3"}>
      <Head title="Brighter-App | Dashboard" />

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page className={"mt-5 mb-4"}>
              Dashboard
            </BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {/* <Row md="12" lg="12" sm="12" className={"mb-3"}>
        {totalTask &&
          totalTask.length &&
          totalTask.map((item) => {
            return (
              <Col md="3" lg="3" sm="6">
                <Card>
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">{STATUSES[item.status]}</h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount" style={{ fontSize: "30px" }}>
                            {item.count}
                          </div>
                        </div>
                        <div className="info">
                          <span className="font-weight-bolder">{item.weeklyCount}</span>
                          <span> vs. last week</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
      </Row> */}
      <Row>
        {plantTaskDataKeys &&
          plantTaskDataKeys.map((taskData, index) => {
            const isStringPage = switchRoutes(taskData[0]).includes("string-maintenance");

            return (
              <Col md="6" lg="6" sm="12" className="p-2" m="2" key={`dashboard-${index}`}>
                <Card>
                  <CardTitle className="m-3" style={{ fontWeight: "bolder", fontSize: "20px" }}>
                    {header(taskData[0])}
                  </CardTitle>
                  <CardBody>
                    <Row className="match-height form-row justify-content-center">
                      {taskData[1].length &&
                        taskData[1].map((data, index) => {
                          subTitleData(data);

                          return (
                            <Col xs="6" sm="3" md="6" lg="3" key={`data-${index}`}>
                              <Card
                                id="count-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  history.push({
                                    pathname: switchRoutes(taskData[0]),
                                    search: `?status=${
                                      data.status === "READY_FOR_APPROVAL" && isStringPage ? "VALIDATE" : data?.status
                                    }&dashboard=yes`,
                                  });
                                }}
                              >
                                {data.status === "OPEN" && (
                                  <CardTitle
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      fontSize: "40px",
                                      color: "red",
                                    }}
                                  >
                                    {data.count}
                                  </CardTitle>
                                )}
                                {(data.status === "COMPLETED" || data.status === "CLOSED") && (
                                  <CardTitle
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      fontSize: "40px",
                                      color: "blue",
                                    }}
                                  >
                                    {data.count}
                                  </CardTitle>
                                )}
                                {(data.status === "READY_FOR_APPROVAL" || data.status === "VALIDATE") && (
                                  <CardTitle
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      fontSize: "40px",
                                      color: "deeppink",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.count}
                                  </CardTitle>
                                )}
                                {data.status === "IN_PROGRESS" && (
                                  <CardTitle
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      fontSize: "40px",
                                      color: "green",
                                    }}
                                  >
                                    {data.count}
                                  </CardTitle>
                                )}
                                <CardBody
                                  style={{ display: "flex", justifyContent: "center", fontWeight: "bolder" }}
                                  className="p-0"
                                >
                                  {titleData(data.status)}
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
      </Row>
      {!plantLoading && (
        <PreviewCard className={"mt-10 mb-5 "}>
          <Row>
            {plantItems && plantItems.length ? (
              plantItems.map((item) => {
                return (
                  <Col md="6" lg="4">
                    <PreviewAltCard className="mb-4">
                      <div className="team">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="user-card user-card-s2">
                            <UserAvatar theme="primary" className="lg" text={findUpper(item.name)}></UserAvatar>
                          </div>
                          <div className="team-details">
                            <Link id="pvplant-view" to={`${CLIENT_PVPLANT_VIEW}`.replace(":plantId", item.id)}>
                              <div className="user-info">
                                <h6>{item.name}</h6>
                              </div>
                              <p>
                                <Badge className="text-capitalize" color={plantStatusObj[item.status]} pill>
                                  {PLANTSTATUSES[item.status] || "-"}
                                </Badge>
                              </p>
                            </Link>
                          </div>
                        </div>
                        <ul className="team-info ">
                          <li className="justify-content-between">
                            <span>Acronomy</span>
                            <span
                              data-toggle="tooltip"
                              title={item.identifier}
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                            >
                              {item?.identifier || "N/A"}
                            </span>
                          </li>
                          <li>
                            <span>Size</span>
                            <span
                              data-toggle="tooltip"
                              title={item.size}
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                            >
                              {item?.size || "N/A"}
                            </span>
                          </li>
                          <li>
                            <span>Address</span>
                            <span
                              data-toggle="tooltip"
                              title={item.address}
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                            >
                              {item?.address || "N/A"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </PreviewAltCard>
                  </Col>
                );
              })
            ) : (
              <h5>No plants found</h5>
            )}
          </Row>
        </PreviewCard>
      )}

      {taskLoading && <Skeleton count={1} className="w-100 " height={200} />}
    </Block>
  );
};
export default HomePage;
