/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import EquipmentAndSpareView from "../components/EquipmentAndSpareView";
import { EquipmentAndSpareContext } from "../EquipmentSparesProvider";

const EquipmentAndSpareViewContainer = ({ equipmentId, onSuccessfulModal, setMode }) => {
  const { loadClientEquipment } = useContext(EquipmentAndSpareContext);
  useEffect(() => {
    if (equipmentId) {
      loadClientEquipment(equipmentId);
    }
  }, [equipmentId]);

  return (
    <>
      <EquipmentAndSpareView setMode={setMode} onSuccessfulModal={onSuccessfulModal} />
    </>
  );
};
export default EquipmentAndSpareViewContainer;
