/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  updateClientUserPassword,
  updateClientUserAPI,
  getClientUserAPI
} from "./UserManagementRepository";

export const UserManagementContext = React.createContext();

export const UserManagementProvider = (props) => {
  const [currentViewUser, setCurrentViewUser] = React.useState(null);
  
  // LOADERS
  const [inlineLoading, ilState] = useBoolean(false);
  const [loadingUserView, lswState] = useBoolean(false);

  const userClientPassword = async (userId, params) => {
    ilState.on();
    try {
      await updateClientUserPassword(userId, { password: params.password });
    } catch (e) {
      throw e;
    } finally {
      ilState.off();
    }
  };
  ///// VIEW USER
  const loadUser = async (userId) => {
    try {
      const response = await getClientUserAPI(userId);
      setCurrentViewUser(response.user);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  const updateUser = async (userId, updateParams) => {
    try {
      lswState.on();
      await updateClientUserAPI(userId, updateParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  const mContext = {
    userClientPassword,
    inlineLoading,
    loadUser,
    updateUser,
    currentViewUser,
    loadingUserView
  };

  return <UserManagementContext.Provider value={mContext}>{props.children}</UserManagementContext.Provider>;
};

export const withUserManagementProvider = (Container, containerProps) => (props) =>
  (
    <UserManagementProvider>
      <Container {...containerProps} />
    </UserManagementProvider>
  );