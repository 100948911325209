import React from "react";
import BgeLogo from "../../images/bge_logo.png";
import "./style.css"
const Footer = () => {

  return (
    <div className="nk-footer p-1">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            <a href={"https://brightergreenengineering.com"} className="nav-link px-0" target="_blank" rel="noopener noreferrer">
              <img className="logo-dark logo-img" src={BgeLogo} alt="Freedom Blog" />
              <div class='center__text'>
                <span style={{ fontSize: "8px", color: "yellowgreen" }}>POWERED BY</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
