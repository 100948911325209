/* eslint-disable react-hooks/exhaustive-deps */
import React,{useContext} from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import UserForm from "../user_profile/UserProfileUpdate";
import { withUserManagementProvider} from "../UserManagementProvider";
import { AuthContext } from "../../../providers/AuthContext.provider";

const UserManagementFormContainer = props => {
  
const {currentUser} = useContext(AuthContext)

const userId = currentUser.id

  const title = "Update User Management"

  return (
    <React.Fragment>
      <Head title={title} />
      <Content>
        <UserForm userId={userId} />
      </Content>
    </React.Fragment>
  );
}

export default withUserManagementProvider(UserManagementFormContainer);