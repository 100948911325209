import { format } from "date-fns";
import React, { useContext } from "react";
import { Spinner } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent } from "../../../../components/Component";
import { StringManagementContext } from "../StringProvider";
import Head from "../../../../layout/head/Head";

const StringView = ({ sm, updateSm }) => {
  const stringManagementContext = useContext(StringManagementContext);
  const { currentViewString } = stringManagementContext;
  if (!currentViewString) return <Spinner />;
  return (
    <div className="overflow-auto h-max-450px">
      <Head title="Brighter-App | String Maintenance | View" />
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md" className="d-flex justify-content-end">
          <BlockBetween>
            <BlockHeadContent className="align-self-end"></BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-item">
              <div id="corrective-ticket-name" className="data-col">
                <span className="data-label">Identifier</span>
                <span className="data-value"> {currentViewString.identifier || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Plant</span>
                <span className="data-value">{currentViewString?.plant?.name || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Status</span>
                <span className="data-value">
                  {currentViewString?.status === "VALIDATE" ? "READY FOR APPROVAL" : currentViewString?.status}{" "}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Added At</span>
                <span className="data-value">
                  {" "}
                  {currentViewString?.addedAt
                    ? format(new Date(currentViewString.addedAt ? currentViewString.addedAt : null), "dd MMM yyyy")
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Investigated At</span>
                <span className="data-value">
                  {currentViewString?.investigatedAt
                    ? format(
                        new Date(currentViewString.investigatedAt ? currentViewString.investigatedAt : null),
                        "dd MMM yyyy"
                      )
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Repaired At</span>
                <span className="data-value">
                  {currentViewString?.repairedAt
                    ? format(
                        new Date(currentViewString.repairedAt ? currentViewString.repairedAt : null),
                        "dd MMM yyyy"
                      )
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Failure Reason</span>
                <span className="data-value">{currentViewString.failureReason?.name || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Work Comments</span>
                <span className="data-value">{currentViewString.workComments || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Monitoring Comments</span>
                <span className="data-value">{currentViewString.monitoringComments || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">No Of Strings Per duct</span>
                <span className="data-value">{currentViewString.noOfStringsPerDuct || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
          </div>

          <div className="nk-data data-list">
            <div>
              <small class="text-muted">
                CreatedAt: {format(new Date(currentViewString?.created_at || null), "dd MMM yyyy HH:mm")}{" "}
              </small>
            </div>
            <div>
              <small class="text-muted">
                UpdatedAt: {format(new Date(currentViewString?.updated_at || null), "dd MMM yyyy HH:mm")}{" "}
              </small>
            </div>
          </div>
        </Block>
      </Block>
    </div>
  );
};
export default StringView;
