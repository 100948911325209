/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import GlobalSearchListCompact from "./GlobalSearch";
import { useParams } from "react-router-dom";
import { withGlobalSearchProvider, GlobalSearchContext } from "../../providers/GlobalSearchProvider";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const GlobalSearchContainer = (props) => {
  const location = useLocation().search;
  const searchParams = new URLSearchParams(location).get("searchText");
  const { loadGlobalSearchList } = useContext(GlobalSearchContext);

  useEffect(() => {
    if (searchParams) {
      loadGlobalSearchList(searchParams);
    }
  }, [searchParams]);

  return (
    <React.Fragment>
      <Head title="Brighter App | Global Search" />
      <Content>
        <GlobalSearchListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withGlobalSearchProvider(GlobalSearchContainer);
