import React from "react";
import useBoolean from "../../hooks/useBoolean";
import {getClientPlants,getTotalTasks} from "./HomeRepository"

export const HomeContext = React.createContext()

export const HomeProvider = props => {
  
  const [plantsList, setPlantsList] = React.useState([]);
  const [totalTask,setTotalTask] = React.useState([])
  //Loaders
  const [plantLoading, plState] = useBoolean(false);
  const [taskLoading, tlState] = useBoolean(false);

  const loadClientAllPlants = async () => {
    plState.on();

    try {
      const response = await getClientPlants();
      setPlantsList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      plState.off();
    }
  };

  const loadClientTotalTasks = async () => {
    tlState.on();

    try {
      const response = await getTotalTasks();
      setTotalTask(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      tlState.off();
    }
  };

  const mContext = {loadClientAllPlants,plantLoading,plantsList,totalTask,taskLoading,loadClientTotalTasks}
  return <HomeContext.Provider value={mContext}>
    {props.children}
  </HomeContext.Provider>
}

export const withHomeProvider = (Container, containerProps) => props => <HomeProvider>
  <Container {...containerProps} />
</HomeProvider>