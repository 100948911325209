import apiService from "../services/ApiService";
import {
    CLIENT_GLOBAL_SEARCH
} from '../constants/endpoints';


export const getGlobalSearch = async filterParams => {
    const response = await apiService.get(CLIENT_GLOBAL_SEARCH, {
        queryParams: filterParams,
    })
    return response
}
