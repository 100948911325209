const RECORD_TYPE = [
  { label: "Add", value: "ADD" },
  { label: "Subtract", value: "SUBTRACT" },
  { label: "Transfer", value: "TRANSFER" },
];
const CAUSE = [
  { label: "Addition", value: "Addition" },
  { label: "Transportation", value: "Transportation" },
  { label: "Installation", value: "Installation" },
  { label: "Uninstall", value: "Uninstall" },
  { label: "Service", value: "Service" },
  { label: "Return", value: "Return" },
  { label: "Other", value: "Other" },
];
const STATUSES = [
  { label: "Less than contractual quantity", value: "less" },
  { label: "Equal to contractual quantity", value: "equal" },
  { label: "Greater than contractual quantity", value: "greater" },
];
export { RECORD_TYPE, CAUSE, STATUSES };
export const TASK = "TASK";
