import React, { useState } from 'react'

export const ForgotPasswordContext = React.createContext()

export const ForgotPasswordProvider = props => {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");

  const mContext = {
    loading, setLoading,
    errorVal, setError
  }

  return <ForgotPasswordContext.Provider value={mContext}>
    {props.children}
  </ForgotPasswordContext.Provider>
}

export const withForgotPasswordProvider = (Container, p) => props => <ForgotPasswordProvider><Container {...props} /></ForgotPasswordProvider>