/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { getFile } from "../../repositories/FileRepository";
import { getFileSizeText } from "../../utils/Utils";
import {
  Block,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../Component";
import { errorToast } from "../toastify/Toastify";
import Card from "./Card";

const Attachments = (props) => {
  const [attachment, setAttachment] = useState([]);
  const { attachments } = props;
  const [files, setFiles] = useState([]);
  const [view, setView] = useState(true);

  useEffect(() => {
    setAttachment(attachments);
  }, []);

  useEffect(() => {
    const setPreviewUrl = async () => {
      for (let i = 0; i < attachment?.length; i++) {
        try {
          const previewUrl = await getFile(attachment[i].files.key);
          files.push(previewUrl);
          setFiles([...files]);
        } catch (e) {
          errorToast({ description: "Error happened on getting the file " });
        }
      }
    };
    if (!files.length) setPreviewUrl();
  }, [attachment]);

  const toggleAttachmentView = () => {
    setView(!view);
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <BlockTitle tag="h6" className="mb-1">
            Attachments
          </BlockTitle>
        </div>
        {
          <div className="d-flex justify-content-end">
            {attachment?.length ? (
              <Button id="client-attach-btn" outline color="primary" onClick={toggleAttachmentView} className="ml-3">
                {(view && <Icon name="view-list-fill" />) || <Icon name="menu-squared" />}
              </Button>
            ) : (
              <span>No Attachment Found</span>
            )}
          </div>
        }
      </div>
      {view && (
        <div className="mt-2">
          <Row>
            {attachment?.map((item, key) => {
              return (
                <Col md="4" sm="6" xs="12" className="mb-4">
                  <Card type={item.files.mimetype} src={files[key]} file={item.files} />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
      {!view && (
        <>
          <Block className={"py-3"}>
            <DataTable className="card-stretch">
              <DataTableBody compact className={"border-top  border-bottom border-light"}>
                <DataTableHead>
                  <DataTableRow className="font-weight-bold">
                    <span className="sub-text">Name</span>
                  </DataTableRow>
                  <DataTableRow className="font-weight-bold">
                    <span className="sub-text">Size</span>
                  </DataTableRow>
                  <DataTableRow className="font-weight-bold" size="md">
                    <span className="sub-text">Created By</span>
                  </DataTableRow>
                  <DataTableRow className="font-weight-bold" size="md">
                    <span className="sub-text">Created At</span>
                  </DataTableRow>
                </DataTableHead>
                {attachment?.map((item, key) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow>
                        <span>
                          <a href={files[key]} target="_blank" rel="noreferrer">
                            {item.files.name}
                          </a>
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>{getFileSizeText(item.files.size)}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{`${item.files.user?.firstName} ${item.files.user?.lastName}`}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{format(new Date(item?.created_at || null), "dd/MM/yyyy k:m aaa")}</span>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })}
              </DataTableBody>
            </DataTable>
          </Block>
        </>
      )}
    </>
  );
};

export default Attachments;
