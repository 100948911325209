/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useParams } from "react-router-dom";
import ViewTicket from "../components/ViewTicket";
import { TicketManagementContext, withTicketManagementProvider } from "../TicketManagementProvider";

const ViewTicketContainer = (props) => {
  const { ticketId } = useParams();
  const ticketManagementContext = useContext(TicketManagementContext);
  const { loadPvPlantTicket } = ticketManagementContext;

  useEffect(() => {
    if (ticketId) loadPvPlantTicket(ticketId);
  }, [ticketId]);

  return (
    <React.Fragment>
      <Head title={`${ticketId} - Ticket`} />
      <Content>
        <ViewTicket />
      </Content>
    </React.Fragment>
  );
};

export default withTicketManagementProvider(ViewTicketContainer);
