import React from "react";

const useBoolean = (initialValue = false) => {
  const [boolState, setBoolState] = React.useState(initialValue);

  const updateBoolState = {
    on: () => setBoolState(true),
    off: () => setBoolState(false),
    toggle: () => setBoolState(!boolState),
  };

  return [boolState, updateBoolState];
};

export default useBoolean;
