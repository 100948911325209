/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import PreventativeTaskListCompact from "../components/PreventativeTaskList";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PreventativeTaskContainer = (props) => {
  const location = useLocation().search;

  const { loadPvPLantTaskList } = useContext(PvPlantContext);
  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");

  const { pagination, filterParams, loadFilterOptions } = useContext(PvPlantContext);
  const plantId = props.plantId;
  useEffect(() => {
    if (plantId && !status) {
      loadPvPLantTaskList(plantId, "preventative");
    }
    if (plantId && status) {
      loadPvPLantTaskList(plantId, "preventative", status, dashboard);
    }
  }, [pagination, filterParams, plantId, status]);
  useEffect(() => {
    loadFilterOptions();
  }, []);
  return (
    <React.Fragment>
      <Content>
        <PreventativeTaskListCompact plantId={plantId} />
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantProvider(PreventativeTaskContainer);
