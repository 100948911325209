import { useContext } from "react";
import { Card } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../../components/Component";
import { EquipmentAndSpareContext } from "../../EquipmentSparesProvider";

const ViewComponent = ({ setMode, onSuccessfulModal }) => {
  const { currentClientEquipment } = useContext(EquipmentAndSpareContext);

  return (
    <>
      <Block className={"bg-white p-4"}>
        <BlockHead size="lg">
          <div className="justify-content-mb-between d-md-flex">
            <BlockHeadContent>
              <BlockTitle tag="h4">{currentClientEquipment?.assets?.model}'s Information</BlockTitle>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block size="lg" className="mt-2">
          <Card className="card-bordered p-2">
            <div className="profile-ud-list" style={{ maxWidth: "100%" }}>
              <div className="profile-ud-item w-100">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Equipment Name</span>
                  <span className="profile-ud-value">{currentClientEquipment?.assets?.model || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Supplier</span>
                  <span className="profile-ud-value">{currentClientEquipment?.assets?.manufacturers?.name || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Asset Category</span>
                  <span className="profile-ud-value">
                    {currentClientEquipment?.assets?.assetCategories?.name || "-"}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Plant Name</span>
                  <span className="profile-ud-value">{currentClientEquipment?.plants?.name || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Contractual Quantity</span>
                  <span className="profile-ud-value">{currentClientEquipment?.contractualQuantity || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Total Spares</span>
                  <span className="profile-ud-value">{currentClientEquipment?.quantity || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Installed Quantity</span>
                  <span className="profile-ud-value">{currentClientEquipment?.installedQuantity || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Spare Quantity</span>
                  <span className="profile-ud-value">{currentClientEquipment?.spareQuantity || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">External Warehouse Quantity</span>
                  <span className="profile-ud-value">{currentClientEquipment?.externalWarehouseQuantity || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Bge Warehouse Quantity</span>
                  <span className="profile-ud-value">{currentClientEquipment?.bgeWarehouseQuantity || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Supplier premises Quantity</span>
                  <span className="profile-ud-value">{currentClientEquipment?.supplierPremisesQuantity || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Part Number</span>
                  <span className="profile-ud-value">{currentClientEquipment?.partNumber || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Is Key Component</span>
                  <span className="profile-ud-value">{currentClientEquipment.isKeyComponent ? "Yes" : "No"}</span>
                </div>
              </div>
              <div style={{ width: "100%" }} className="profile-ud-item">
                <div lg="12" className="profile-ud wider">
                  <span style={{ width: "40%" }} className="profile-ud-label">Details</span>
                  <span style={{ width: "60%" }} dangerouslySetInnerHTML={{ __html: currentClientEquipment?.description || "-" }} className="profile-ud-value text-editor" />
                </div>
              </div>
            </div>
          </Card>
        </Block>
      </Block>
    </>
  );
};
export default ViewComponent;
