import { ModalBody, Modal, ModalHeader } from "reactstrap"
import { Icon } from "../../components/Component"

const ModalViewer = (props) => {
    const { isOpen, toggleModal, component, title ,size} = props
    return <Modal isOpen={isOpen} toggle={toggleModal} size={size}>
        <ModalHeader
            toggle={toggleModal}
            close={
                <button className="close" onClick={toggleModal}>
                    <Icon name="cross" />
                </button>
            }
        >
            {title}
        </ModalHeader>
        <ModalBody>
            {component}
        </ModalBody>
        {/* <ModalFooter className="bg-light">
  <span className="sub-text">Modal Footer Text</span>
</ModalFooter> */}
    </Modal>
}
export default ModalViewer