/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import RemedialTaskListCompact from "../components/RemedialTaskList";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const RemedialTaskContainer = (props) => {
  const location = useLocation().search;

  const { loadPvPLantTaskList } = useContext(PvPlantContext);
  const status = new URLSearchParams(location).get("status");
  const { pagination, filterParams, loadFilterOptions } = useContext(PvPlantContext);

  const plantId = props.plantId;

  useEffect(() => {
    if (plantId && !status) {
      loadPvPLantTaskList(plantId, "remedial");
    }
    if (plantId && status) {
      loadPvPLantTaskList(plantId, "remedial", status);
    }
  }, [pagination, filterParams, plantId, status]);

  useEffect(() => {
    loadFilterOptions();
  }, []);
  return (
    <React.Fragment>
      <Content>
        <RemedialTaskListCompact plantId={plantId} />
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantProvider(RemedialTaskContainer);
