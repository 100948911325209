import React, { useState } from 'react'

export const LoginContext = React.createContext()

export const LoginProvider = props => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");

  const mContext = {
    loading, setLoading,
    passState, setPassState,
    errorVal, setError
  }

  return <LoginContext.Provider value={mContext}>
    {props.children}
  </LoginContext.Provider>
}

export const withLoginProvider = (Container, p) => props => <LoginProvider><Container {...props} /></LoginProvider>