export const selectedMaintenanceTab = {
  "corrective-maintenance": "Corrective Maintenance Tickets",
  "preventive-maintenance": "Preventative Maintenance Tickets",
  "remedial-maintenance": "Remedial Maintenance Tickets",
  "string-maintenance": "String Lists",
};

export const CATEGORY = {
  CORRECTIVE: "corrective",
  REMEDIAL: "remedial",
  PREVENTATIVE: "preventative",
  PREVENTATIVE_MASTER: "preventative_master",
  PREVENTATIVE_SCHEDULE: "preventative_schedule",
};
