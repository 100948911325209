import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilterBadge";
import { CLIENT_PVPLANT_VIEW } from "../../../constants/routes";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import ModalViewer from "../ModalViewer";
import { PvPlantContext } from "../PvPlantProvider";
import ExportPlant from "./ExportForm";
import PvplantFilter from "./PvplantFilter";

const PvPlantList = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const pvPlantContext = useContext(PvPlantContext);

  const {
    pvPlantsList,
    pagination,
    pvPlantLoading,
    showFilter,
    updateShowListPage,
    updateShowListSize,
    plantOptions,
    isFilterApplied,
    handleApplyFilter,
    filterOptions,
    sfState,
    filterParams,
  } = pvPlantContext;
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [sm, updateSm] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  //   // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  //   // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const createExport = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const onSuccessfulModal = () => {
    toggleExportModal();
  };
  const getExportComponent = () => {
    return (
      <ExportPlant
        onSuccessfulModal={onSuccessfulModal}
        filterParams={filterParams}
        setIsExportModalOpen={setIsExportModalOpen}
      />
    );
  };
  const plantManagerSelectedFilter = filterParams.plantManager.map((item, key) => {
    return plantOptions.plantManager?.[plantOptions.plantManager?.findIndex((data) => data.value === item)];
  });

  const plantSelectedFilter = filterParams.plant.map((item, key) => {
    return plantOptions.plant?.[plantOptions.plant?.findIndex((data) => data.value === item)];
  });

  const PlantListData = pvPlantsList?.items || [];

  return (
    <React.Fragment>
      <Head title="Brighter-App | PV Plant" />

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page className={"mt-5"}>
              PV Plants List
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {pvPlantsList?.pagination?.totalCount} PV Plants.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedTaskStatus} title={"Status"} isMap={false} isStr={false} />
              <FilteredByList data={plantSelectedFilter} title={"Plant"} isMap={true} isStr={false} />

              <FilteredByList data={plantManagerSelectedFilter} title={"Plant manager"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.startDate} title={"Start Date"} isMap={false} isStr={true} />
              <FilteredByList data={filterParams.resolvedDate} title={"Resolved Date"} isMap={false} isStr={true} />
              <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <a
                      className={`btn btn-white btn-outline-light ${PlantListData.length > 0 ? "" : "disabled"}`}
                      type="export"
                      // isLoading={exportLoading}
                      onClick={() => createExport()}
                      href="#select"
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
          {/* <BlockHeadContent></BlockHeadContent> */}
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="search-pvplant-btn"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <div id="pvplant-filter" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      id="cancle-pvplant-search"
                      className="search-back btn-icon toggle-search  active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      id="search-input-pvplant"
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by plant name and acronym. Enter to search "
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                        if (e.keyCode === 88) {
                          setSearchText("");
                        }
                      }}
                    />
                    <Button id="search-text" className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow style={{ display: "flex" }}>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    id="pvplant-sort"
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "name" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "name")
                        : handleNameSort("ASC", "name");
                    }}
                  >
                    PV Plant
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      id="pvplant-asc"
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "name");
                      }}
                      name={`${
                        filterParams.sortingField === "name" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      id="pvplan-desc"
                      onClick={() => {
                        handleNameSort("DESC", "name");
                      }}
                      name={`${
                        filterParams.sortingField === "name" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "identifier" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "identifier")
                        : handleNameSort("ASC", "identifier");
                    }}
                  >
                    Acronym
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingField === "identifier" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingField === "identifier" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
                <div className="d-flex " style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "postal_code" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "postal_code")
                        : handleNameSort("ASC", "postal_code");
                    }}
                  >
                    Postal Code
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "postal_code");
                      }}
                      name={`${
                        filterParams.sortingField === "postal_code" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "postal_code");
                      }}
                      name={`${
                        filterParams.sortingField === "postal_code" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                        ? handleNameSort("DESC", "status")
                        : handleNameSort("ASC", "status");
                    }}
                  >
                    BGE status
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "status"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "size"
                        ? handleNameSort("DESC", "size")
                        : handleNameSort("ASC", "size");
                    }}
                  >
                    Size MW
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "size");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "size"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "size");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "size"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plantManagerId"
                        ? handleNameSort("DESC", "plantManagerId")
                        : handleNameSort("ASC", "plantManagerId");
                    }}
                  >
                    Plant Manager
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "plantManagerId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plantManagerId"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "plantManagerId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "plantManagerId"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              {/* <DataTableRow><span className="sub-text">Actions</span></DataTableRow> */}
            </DataTableHead>
            {/*Head*/}
            <div>
              <Sidebar toggleState={showFilter}>
                <div>
                  <PvplantFilter filterOptions={filterOptions} />
                </div>
              </Sidebar>
            </div>
            {pvPlantLoading ? (
              <></>
            ) : (
              PlantListData.map((item) => {
                return (
                  <DataTableItem>
                    <DataTableRow>
                      {/* {item && item.status === "ACTIVE" ? */}
                      <Link id="pvplant-view" to={`${CLIENT_PVPLANT_VIEW}`.replace(":plantId", item.id)}>
                        <div className="user-card">
                          <UserAvatar theme={"primary"} className="xs" text={findUpper(item.name)} />
                          <div className="user-name">
                            <span
                              data-toggle="tooltip"
                              title={item.name}
                              style={{
                                width: "90px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              className="tb-lead"
                            >
                              {item.name || "-"}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item.identifier}
                        style={{
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        {item.identifier || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>{item.postalCode || "-"}</span>
                    </DataTableRow>
                    {/* <DataTableRow size="md">
                      <span>{item.clients?.name || "NA"}</span>
                    </DataTableRow> */}
                    <DataTableRow size="md">
                      <span>{item?.status || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{item?.size || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>
                        {item.plantManager?.firstName} {item.plantManager?.lastName || "-"}
                      </span>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {pvPlantLoading && <Skeleton count={10} className="w-100" />}

          <div className="card-inner">
            {pvPlantsList.pagination && pvPlantsList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={pvPlantsList.pagination.totalCount}
                paginate={updateShowListPage}
                currentPage={pvPlantsList.pagination.currentPage}
                paginatePage={updateShowListSize}
              />
            ) : (
              !pvPlantLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="PV Plant - Select the Export Fields"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default PvPlantList;
