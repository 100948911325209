import { useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classnames from "classnames";
import AttachmentComponent from "./view_component/AttachmentComponent";
import TransactionComponent from "./view_component/TransactionComponent";
import ViewComponent from "./view_component/ViewComponent";

const EquipmentAndSpareView = ({ setMode, onSuccessfulModal }) => {

    const [currentTab, setCurrentTab] = useState(1)
    return <>
        <Nav tabs className="ml-4" style={{ display: "flex", justifyContent: "space-around" }}>
            <NavItem>
                <NavLink
                    id="Equipment-Info"
                    href="#"
                    className={classnames({ active: currentTab === 1 })}
                    onClick={(ev) => {
                        setCurrentTab(1)
                    }}
                >
                    Equipment And Spares Info
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    id="Attachments"
                    href="#"
                    className={classnames({ active: currentTab === 2 })}
                    onClick={(ev) => {
                        setCurrentTab(2)

                    }}
                >
                    Attachments
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    id="Transactions"
                    href="#"
                    className={classnames({ active: currentTab === 3 })}
                    onClick={(ev) => {
                        setCurrentTab(3)
                    }}
                >
                    Transactions
                </NavLink>
            </NavItem>
            <hr />
        </Nav>
        <TabContent className="mt-2" activeTab={currentTab}>
            <TabPane tabId={1}>
                <ViewComponent setMode={setMode} onSuccessfulModal={onSuccessfulModal} />
            </TabPane>
            <TabPane tabId={2}>
                <AttachmentComponent />
            </TabPane>
            <TabPane tabId={3}>
                <TransactionComponent />
            </TabPane>
        </TabContent>
    </>
}
export default EquipmentAndSpareView