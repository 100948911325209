import { CLIENT_LOGIN, CLIENT_USER_LOOKUP,CLIENT_FORGOT_PASSWORD ,CLIENT_RESET_PASSWORD} from "../constants/endpoints";
import apiService from "../services/ApiService";

export async function loginUser(params) {
    const response = await apiService.post(CLIENT_LOGIN, {
      body: params
    })
    return response
}

export async function userLookup() {
  const response = await apiService.get(CLIENT_USER_LOOKUP)
  return response
}

export async function forgotPassword(params) {
  const response = await apiService.post(CLIENT_FORGOT_PASSWORD, {
    body: params,
  });
  return response;
}

export async function resetPassword(resetPasswordToken, params) {
  const response = await apiService.put(CLIENT_RESET_PASSWORD, {
    body: params,
    pathParams: { resetPasswordToken },
  });
  return response;
}