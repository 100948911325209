/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import CorrectiveTaskListCompact from "../components/CorrectiveTaskList";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CorrectiveTaskContainer = (props) => {
  const location = useLocation().search;

  const { loadPvPLantTaskList } = useContext(PvPlantContext);
  const { pagination, filterParams, loadFilterOptions } = useContext(PvPlantContext);
  const status = new URLSearchParams(location).get("status");

  const plantId = props.plantId;

  useEffect(() => {
    if (plantId && !status) {
      loadPvPLantTaskList(plantId, "corrective");
    }
    if (plantId && status) {
      loadPvPLantTaskList(plantId, "corrective", status);
    }
  }, [pagination, filterParams, plantId, status]);

  useEffect(() => {
    loadFilterOptions();
  }, []);
  return (
    <React.Fragment>
      <Content style={{ padding: 0 }} className="p-0">
        <CorrectiveTaskListCompact plantId={plantId} />
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantProvider(CorrectiveTaskContainer);
