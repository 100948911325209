import React from "react";
import { Row, Col } from "../../components/Component";

const AuthFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="nk-footer nk-auth-footer-full">
      <div className="container wide-lg">
        <Row className="g-3">
          <Col lg={6} className="order-lg-last">
            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href={"https://brightergreenengineering.com/wp-content/uploads/2019/08/Terms-and-Conditions-1.pdf"}
                  rel="noreferrer"
                >
                  Terms &amp; Condition
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href={"https://brightergreenengineering.com/wp-content/uploads/2019/08/Privacy-Policy.pdf"}
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href={"https://brightergreenengineering.com/CONTACT/"}
                  rel="noreferrer"
                >
                  Help
                </a>
              </li>
            </ul>
          </Col>
          <Col lg="6">
          <div className="nk-block-content text-center text-lg-left">
              <a href={"https://brightergreenengineering.com"} rel="noopener noreferrer">
                {" "}
                <p className="text-soft"> &copy; Brighter Green Engineering {currentYear}</p>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AuthFooter;
