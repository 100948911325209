import apiService from "../../services/ApiService";
import {
  CLIENT_USER_MANAGEMENT_PASSWORD_API,
  CLIENT_USER_MANAGEMENT_UPDATE_API,
  CLIENT_USER_MANAGEMENT_VIEW_API
} from "../../constants/endpoints";

export const updateClientUserPassword= async (userId, params) => {
  const response = await apiService.put(CLIENT_USER_MANAGEMENT_PASSWORD_API, {
    body: params,
    pathParams: { userId },
  });
  return response;
};

export const updateClientUserAPI = async (userId, params) => {
  const response = await apiService.put(CLIENT_USER_MANAGEMENT_UPDATE_API, {
    body: params,
    pathParams: { userId },
  });
  return response;
};

export const getClientUserAPI = async (userId) => {
  const response = await apiService.get(CLIENT_USER_MANAGEMENT_VIEW_API, {
    pathParams: { userId },
  });
  return response;
};