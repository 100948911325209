import { format } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";
import { Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilterBadge";
import { selectedMaintenanceTab } from "../../../constants/common";
import Head from "../../../layout/head/Head";
import ModalViewer from "../../plant_management/ModalViewer";
import { TicketManagementContext } from "../TicketManagementProvider";
import TicketViewContainer from "../containers/TicketViewContainer";
import ExportForm from "./ExportForm";
import TicketListFilter from "./TicketsListFilter";
import { MONTH, statusColorTheme } from "../strings/components/constants";

const TicketListCompact = ({ ticketsList, viewingPage }) => {
  // REFS
  const focusSearchRef = React.useRef();

  const location = useLocation().search;

  const TaskId = JSON.parse(new URLSearchParams(location).get("id"));

  const ticketManagementContext = useContext(TicketManagementContext);

  const isPreventativeMaintenance = viewingPage === "PREVENTATIVE";

  //ticketsList,
  const { pagination, ticketsLoading, filterOptions } = ticketManagementContext;
  const { filterParams, handleApplyFilter, sfState, showFilter, isFilterApplied } = ticketManagementContext;
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ticket, setTicket] = useState("");
  const [view, setView] = useState("");
  const [isToggleTicket, setToggleTicket] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };
  const VIEW = {
    VIEW: "VIEW",
  };
  const viewTicket = (ticketId) => {
    setTicket(ticketId);
    setView(VIEW.VIEW);
    toggleModal();
  };
  useMemo(() => {
    if (TaskId) {
      viewTicket(TaskId);
    }
  }, []);

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <TicketViewContainer
          isPreventativeMaintenance={isPreventativeMaintenance}
          ticketId={ticket}
          setIsOpen={setIsOpen}
        />
      );
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const onSuccessfulModal = () => {
    toggleExportModal();
  };

  const category = (categoryName) => {
    switch (categoryName) {
      case "Corrective Maintenance Tickets":
        return "corrective";
      case "Preventative Maintenance Tickets":
        return "preventative";
      case "Remedial Maintenance Tickets":
        return "remedial";
      default:
        return "N/A";
    }
  };

  const taskCurrentStatus = filterOptions?.taskStatuses?.map((item) => {
    return item?.value;
  });
  const getExportComponent = () => {
    return (
      <ExportForm
        isPreventativeMaintenance={isPreventativeMaintenance}
        onSuccessfulModal={onSuccessfulModal}
        filterParams={filterParams}
        taskCurrentStatus={taskCurrentStatus}
        setIsExportModalOpen={setIsExportModalOpen}
        category={category(tabName)}
      />
    );
  };
  const createExport = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  let engineers = [];
  const currentItems = ticketsList?.items || [];

  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }
  const assignedEngineerSelectedData = filterParams.selectedAssignedEngineer.map((item, key) => {
    return filterOptions.assignedEngineer?.[
      filterOptions.assignedEngineer?.findIndex((status) => status.value === item)
    ];
  });
  const plantSelectedData = filterParams.selectedPlant.map((item, key) => {
    return filterOptions.plants?.[filterOptions.plants?.findIndex((status) => status.value === item)];
  });

  const { pathname } = useLocation();

  const selectedTab = pathname.split("/");

  const tabName = selectedMaintenanceTab[selectedTab[selectedTab.length - 1]];

  return (
    <React.Fragment>
      <Head
        title={
          tabName === "Corrective Maintenance Tickets"
            ? "Brighter-App | Corrective Maintenance"
            : tabName === "Remedial Maintenance Tickets"
            ? "Brighter-App | Remedial Maintenance"
            : "Brighter-App |  Preventative Maintenance "
        }
      />

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle className={"mt-5"} tag={"h3"}>
              {tabName}
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {ticketsList?.pagination?.totalCount} Tickets.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedTaskStatus} title={"Status"} isMap={false} isStr={false} />
              <FilteredByList
                data={assignedEngineerSelectedData}
                title={"Assigned Engineer"}
                isMap={true}
                isStr={false}
              />
              <FilteredByList data={plantSelectedData} title={"Plant"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.searchText} title={"Search By"} isNotMap={true} isStr={true} />
              <FilteredByList data={filterParams.startDate} title={"Start Date"} isMap={false} isStr={true} />
              <FilteredByList data={filterParams.resolvedDate} title={"Resolved Date"} isMap={false} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <Button
                      className={`btn btn-white btn-outline-light ${currentItems.length > 0 ? "" : "disabled"}`}
                      type="export"
                      isLoading={exportLoading}
                      onClick={createExport}
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <div className="toggle-wrap">
                      <Button
                        className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                        onClick={() => updateTableSm(true)}
                      >
                        <Icon name="menu-right"></Icon>
                      </Button>
                      <div tag="a" className="btn btn-icon " onClick={sfState.toggle}>
                        {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                        <Icon name="filter-alt"></Icon>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by plant name, identifier and ticket. Enter to search "
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact className={"overflow-auto"}>
            <DataTableHead>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                        ? handleNameSort("DESC", "title")
                        : handleNameSort("ASC", "title");
                    }}
                  >
                    Ticket Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "title");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "title");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "title"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                        ? handleNameSort("DESC", "identifier")
                        : handleNameSort("ASC", "identifier");
                    }}
                  >
                    Ticket Identifier
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "identifier"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              {isPreventativeMaintenance && (
                <DataTableRow size="md">
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "code"
                          ? handleNameSort("DESC", "code")
                          : handleNameSort("ASC", "code");
                      }}
                    >
                      Code
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "code");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "code"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("DESC", "code");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "code"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
              )}
              {isPreventativeMaintenance && (
                <DataTableRow size="sm">
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "frequency"
                          ? handleNameSort("DESC", "frequency")
                          : handleNameSort("ASC", "frequency");
                      }}
                    >
                      Frequency{" "}
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "frequency");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "frequency"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setToggleTicket(false);
                          handleNameSort("DESC", "frequency");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "frequency"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
              )}
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                        ? handleNameSort("DESC", "status")
                        : handleNameSort("ASC", "status");
                    }}
                  >
                    Status{" "}
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "status"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>

              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "assigned_to_ids"
                        ? handleNameSort("DESC", "assigned_to_ids")
                        : handleNameSort("ASC", "assigned_to_ids");
                    }}
                  >
                    Assigned To{" "}
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "assigned_to_ids");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "assigned_to_ids"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "assigned_to_ids");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "assigned_to_ids"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "started_at"
                        ? handleNameSort("DESC", "started_at")
                        : handleNameSort("ASC", "started_at");
                    }}
                  >
                    Start Date
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "started_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "started_at"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "started_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "started_at"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "resolved_at"
                        ? handleNameSort("DESC", "resolved_at")
                        : handleNameSort("ASC", "resolved_at");
                    }}
                  >
                    Resolved Date{" "}
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "resolved_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "resolved_at"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "resolved_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "resolved_at"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plant_id"
                        ? handleNameSort("DESC", "plant_id")
                        : handleNameSort("ASC", "plant_id");
                    }}
                  >
                    Plant Name{" "}
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "plant_id");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plant_id"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "plant_id");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "plant_id"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              {isPreventativeMaintenance && (
                <DataTableRow size="lg">
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "year"
                          ? handleNameSort("DESC", "year")
                          : handleNameSort("ASC", "year");
                      }}
                    >
                      Year
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "year");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "year"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("DESC", "year");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "year"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
              )}
              {isPreventativeMaintenance && (
                <DataTableRow size="lg">
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <span
                      className="sub-text pr-1"
                      onClick={() => {
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "months"
                          ? handleNameSort("DESC", "months")
                          : handleNameSort("ASC", "months");
                      }}
                    >
                      Months
                    </span>
                    <div className="d-flex flex-column justify-content-center">
                      <Icon
                        style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("ASC", "months");
                        }}
                        name={`${
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "months"
                            ? "caret-up-fill "
                            : "caret-up"
                        } `}
                      ></Icon>

                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleNameSort("DESC", "months");
                        }}
                        name={`${
                          filterParams.sortingOrder === "DESC" && filterParams.sortingField === "months"
                            ? "caret-down-fill"
                            : "caret-down"
                        }`}
                      ></Icon>
                    </div>
                  </div>
                </DataTableRow>
              )}
            </DataTableHead>
            {/*Head*/}

            <Sidebar toggleState={showFilter}>
              <TicketListFilter filterOptions={filterOptions} />
            </Sidebar>

            {ticketsLoading ? (
              <></>
            ) : currentItems.length > 0 ? (
              currentItems.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <div className="user-card">
                        <div
                          className="user-card dropdown-toggle"
                          onClick={() => {
                            viewTicket(item.id);
                          }}
                        >
                          <div className="user-name">
                            <span
                              data-toggle="tooltip"
                              title={item.title}
                              style={{
                                width: "90px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              className="tb-lead"
                            >
                              {item?.title || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item.identifier}
                        style={{
                          display: "inline-block",
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item?.identifier || "-"}
                      </span>
                    </DataTableRow>
                    {isPreventativeMaintenance && (
                      <DataTableRow size="md">
                        <span
                          data-toggle="tooltip"
                          title={item.code}
                          style={{
                            width: "90px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                        >
                          {item?.code || "-"}
                        </span>
                      </DataTableRow>
                    )}
                    {isPreventativeMaintenance && (
                      <DataTableRow size="sm">
                        <span>{item?.frequency || "-"}</span>
                      </DataTableRow>
                    )}
                    <DataTableRow size="md">
                      <Badge className="text-capitalize" style={statusColorTheme[item.status]} pill>
                        <span>{item?.status === "VALIDATE" ? "READY FOR APPROVAL" : item?.status || "N/A"}</span>
                      </Badge>
                    </DataTableRow>

                    <DataTableRow size="lg">
                      <span
                        data-toggle="tooltip"
                        title={item?.assignedToIds
                          ?.map((id, key) => {
                            return engineers[id];
                          })
                          .join(", ")}
                        style={{
                          display: "inline-block",
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item?.assignedToIds
                          ? item?.assignedToIds
                              ?.map((id, key) => {
                                return engineers[id];
                              })
                              .join(", ")
                          : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>
                        {item?.startedAt
                          ? format(new Date(item.startedAt ? item.startedAt : null), "dd MMM yyyy")
                          : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>
                        {" "}
                        {item?.resolvedAt
                          ? format(new Date(item.resolvedAt ? item.resolvedAt : null), "dd MMM yyyy")
                          : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{item?.plant.name || "-"}</span>
                    </DataTableRow>
                    {isPreventativeMaintenance && (
                      <DataTableRow size="lg">
                        <span>{item.year || "-"}</span>
                      </DataTableRow>
                    )}
                    {isPreventativeMaintenance && (
                      <DataTableRow size="lg">
                        <span>{MONTH[item.months] || ""}</span>
                      </DataTableRow>
                    )}
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>
          {ticketsLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {ticketsList && ticketsList.pagination && ticketsList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={ticketsList.pagination.totalCount}
                paginate={ticketManagementContext.updateShowListPage}
                currentPage={ticketsList.pagination.currentPage}
                paginatePage={ticketManagementContext.updateShowListSize}
              />
            ) : (
              !ticketsLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer title={tabName} isOpen={isOpen} size="xl" toggleModal={toggleModal} component={getComponent()} />
      <ModalViewer
        title="Corrective Maintenance - Select the Export Fields"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default TicketListCompact;
