import React, { useState } from 'react'
import {ACCESS_TOKEN, DEVICE_ID, REFRESH_TOKEN } from '../constants/PreferenceKeys'
import { CLIENT_LOGIN_PAGE, CLIENT_RESET_PASSWORD_PAGE, HOME_PAGE, PUBLIC_PATH } from '../constants/routes'
import { loginUser, userLookup,forgotPassword,
  resetPassword, } from '../repositories/AuthRepository'
import md5 from 'md5';
import { getFile } from '../repositories/FileRepository';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const AuthContext = React.createContext()

export const AuthProvider = props => {
  const [currentUser, setCurrentUser] = useState(null)
  const [isDetermined, setIsDetermined] = useState(false)

  const [logoAttachment, setLogoAttachment] = useState("")
  const history = useHistory();

  const getUserName = () => {
    if(!currentUser) return "N/A";
    
    let userName = '';
    if(currentUser.firstName) userName += currentUser.firstName;
    if(currentUser.lastName) userName += ' ' + currentUser.lastName;

    return userName

  }

  const onLogin = async payload => {
    const deviceId = localStorage.getItem(DEVICE_ID)
    if (!deviceId) {
      const bytes = md5(`${payload.email+ new Date()}`);
      localStorage.setItem(DEVICE_ID, bytes)
    }
    try {
      const response = await loginUser({
        ...payload,

        // TODO: GET AUTH DEVICE
        authDevice: {
          deviceId: localStorage.getItem(DEVICE_ID),
          pushToken: "push-token",
          ip: "127.0.0.1"
        }
      })
      localStorage.setItem(ACCESS_TOKEN, response.tokens.auth);
      localStorage.setItem(REFRESH_TOKEN, response.tokens.refresh);
      window.history.pushState(
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
        "auth-login",
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
      );
      setCurrentUser(response.user)
  
      if(response?.user?.resetPassword) {
        history.push(CLIENT_RESET_PASSWORD_PAGE.replace(":resetPasswordToken",response?.tokens?.auth) + "?isNewUser=true");
      }else {
        window.location.reload();
      }
    } catch (e) {
      throw e.message
    }
  }

  const onRegister = payload => {
    // CALL REGISTER API
  }

  const onLogout = () => {
    // CALL LOGOUT API
    // CLEAR LOCAL STATE IF AVAILABLE

    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.setItem(REFRESH_TOKEN);

    setCurrentUser(null)

    window.location.replace(CLIENT_LOGIN_PAGE)

  }

  const lookupUser = async () => {
    try {
      const response = await userLookup()
      setCurrentUser(response.user)

      if(response.logoAttachment){
        const logoPreviewUrl = await getFile(response.logoAttachment.key);
        setLogoAttachment(logoPreviewUrl)
      }

      if (PUBLIC_PATH.includes(window.location.pathname)) {
        window.location.replace(HOME_PAGE)
      }
    } catch (e) {
      console.log('e :>> ', e);
    } finally {
      setIsDetermined(true)
    }
  }

  const onForgotPassword = async (params) => {
    try {
      await forgotPassword({
        ...params,
        // TODO: GET AUTH DEVICE
        authDevice: {
          deviceId: localStorage.getItem(DEVICE_ID),
          pushToken: "push-token",
          ip: "127.0.0.1",
        },
      });
    } catch (e) {
      throw e.message;
    }
  };
  const onResetPassword = async (resetPasswordToken, params, isNewUser) => {
    try {
      await resetPassword(resetPasswordToken, params, isNewUser);
    } catch (e) {
      alert(`Error: ${e}`);
    } finally {
      setIsDetermined(true);
    }
  };

  const mContext = {
    currentUser,
    // userAbilty,
    onLogin,
    onRegister,
    onLogout,
    lookupUser,
    onForgotPassword,
    onResetPassword,
    isAuthenticated: currentUser !== null,
    isDetermined,
    getUserName,
    logoAttachment,
    getShortName: () => {
      if(!currentUser) return "N/A"
      if(currentUser.firstName) return currentUser.firstName
      if(currentUser.lastName) return currentUser.lastName

      return "N/A"
    }
  }

  return <AuthContext.Provider value={mContext}>
    {props.children}
  </AuthContext.Provider>

}

export const withAuthProvider = (Container, containerProps = {}) => {
  return () => <AuthProvider>
    <Container {...containerProps} />
  </AuthProvider>
}